/*eslint-disable*/
import axios from "axios"
import hostName from "~/plugins/hostname"
// var hostname = process.env.dynamicDomain
//     ? "localhost"
//     : window.location.hostname
// var mainDomainLink =
//     hostname && hostname === "localhost"
//         ? process.env.basicDomain
//         : hostname &&
//           (hostname.includes("m.") ||
//               hostname.includes("bd.") ||
//               hostname.includes("www.") ||
//               hostname.includes("fantasy."))
//         ? hostname.replace(/m.|www.|bd.|fantasy./gi, "")
//         : hostname
if (process.client) {
    var mainDomainLink = hostName.DomainCheck(window.location.hostname)
}

export default {
    async apiCallWithOutData(url) {
        // if (process.server) {
        //   return
        // }
        return await axios
            .post(process.env.FantasyAdminUrl + mainDomainLink + url)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },

    async apiCallWithAccessTokenAndWithOutData(url) {
        const urlDomain =
            process.env.setUp !== "gb"
                ? process.env.FantasyFPAdminUrl
                : process.env.FantasyAdminUrl
        return await axios
            .get(urlDomain + mainDomainLink + url, {
                // headers: {
                //     accessToken: localStorage.getItem("accessToken")
                // }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },

    async apiCallWithData(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.FantasyAdminUrl + mainDomainLink + url, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },

    async apiCallWithHeader2(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.FantasyAdminUrl + mainDomainLink + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },

    async apiCallWithHeader(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.FantasyAdminUrl + mainDomainLink + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithHeaderfantasy(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.SPORTSBOOK_API_URL_FANTASY + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },

    async apiCallWithHeadernew(url, data, pAccessToken) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.FantasyAdminUrl + mainDomainLink + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken"),
                    PToken: pAccessToken
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },

    apiCallWithDataForPlaceBet: function (url, formData) {
        return axios
            .post(
                process.env.FantasyAdminUrl + mainDomainLink + url,
                formData,
                {
                    headers: {
                        accessToken: localStorage.getItem("accessToken")
                    }
                }
            )
            .then(function (data) {
                if (data) {
                    data.value = true
                }
                return data
            })
            .catch(function (error) {
                if (error) {
                    error.value = false
                }
                console.log("error error", Object.assign({}, error.response))
                return Object.assign({}, error.response)
            })
    },
    async apiCallWithHeaderUsingGet(url) {
        return axios
            .get(process.env.FantasyAdminUrl + mainDomainLink + url, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },

    async updateApiCallWithHeader(url) {
        if (process.server) {
            return
        }
        return await axios
            .put(
                process.env.FantasyAdminUrl + mainDomainLink + url,
                {},
                {
                    headers: {
                        accessToken: localStorage.getItem("accessToken")
                    }
                }
            )
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },
    async apiCallWithUserManagementWithHeader(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.FantasyUserUrl + url, data, {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithUserManagement(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.FantasyUserUrl + url, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithGetUserManagement(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .get(process.env.FantasyUserUrl + url)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async apiCallWithFantasySockets(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .get(process.env.FantasyAdminSocketUrl + url, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },
    async apiCallWithUserManagementWithoutData(url, data) {
        if (process.server) {
            return
        }
        return await axios
            .post(process.env.FantasyUserUrl + url, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.response
            })
    },
    async getIp() {
        return await axios
            .get("https://api.ipify.org?format=json")
            .then((res) => {
                if (res && res.data && res.data) {
                    return res.data
                }
                return "IP Not Found"
            })
            .catch(function (err) {
                return Object.assign({}, err.response)
            })
    },
    apiCallForFancyMarket: async (url, data) => {
        return await axios
            .post(process.env.CUSTOMER_SERVICE_URL_FANTASY + url, data)
            .then((result) => {
                // console.log("socket output:::::::::::::", result);
                return result
            })
            .catch((error) => {
                return Object.assign({}, error.response)
            })
    },
    async apiCallWithFileUpload(url, data) {
        console.log("apiCallWithFileUpload >>", process.env.s3Url)
        return axios
            .post(process.env.s3Url + url, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },
    async apiCallForDummyMarkets(url, data) {
        return axios
            .post(process.env.SPORTSBOOK_API_URL_FANTASY + url, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    }
}
