import service from "../services/apiService"
export const state = () => ({
    languages: [],
    loading: false
})

export const mutations = {
    setLangDropdown(state, data) {
        state.languages = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}

export const actions = {
    async getAllLanguages({ commit }) {
        try {
            commit("set_loading", true)
            if (sessionStorage.getItem("langDropdown")) {
                commit(
                    "setLangDropdown",
                    await JSON.parse(sessionStorage.getItem("langDropdown"))
                )
                return JSON.parse(sessionStorage.getItem("langDropdown"))
            }
            const res = await service.apiCallWithOutData(
                "/v2/cms/fetchLanguagesPublic"
            )
            if (
                res &&
                res.status &&
                res.status === 200 &&
                res.data &&
                res.data.data
            ) {
                commit("setLangDropdown", res.data.data)
                sessionStorage.setItem(
                    "langDropdown",
                    JSON.stringify(res.data.data)
                )
            } else {
                commit("setLangDropdown", [])
            }
            commit("set_loading", false)
        } catch (error) {
            commit("setLangDropdown", [])
            commit("set_loading", false)
        }
    }
}
