// import moment from 'moment'
import service from "../services/apiService"

export const state = () => {
    return {
        isAuthenticated: false,
        user: null,
        error: null,
        form: {
            dob: new Date()
        },
        valueChanged: false,
        show: " ",
        loginStatus: false
    }
}

export const mutations = {
    set_show(state, value) {
        state.show = value
    },
    set(state, user) {
        state.isAuthenticated = !!user
        state.user = user
    },
    set_error(state, value) {
        state.error = value
    },
    vmodel_data(state, data) {
        state.form[data.vModel] = data.value
        state.valueChanged = true
    },

    set_login_status(state, data) {
        state.loginStatus = data
    }
}

export const actions = {
    async register(
        { commit },
        {
            name,
            userName,
            mobileNo,
            password,
            dob,
            gender,
            whereYouLearnAbout,
            referalCode,
            countryCode,
            affiliateCode,
            captcha
        }
    ) {
        try {
            countryCode = "+" + countryCode
            const userObj = {
                name,
                mobileNo,
                email: userName,
                password,
                dob,
                gender,
                whereYouLearnAbout,
                referalCode,
                countryCode,
                affiliateCode,
                captcha
            }

            const registerObj = await service.apiCallWithUserManagement(
                "/v2/user/signup",
                userObj
            )
            return registerObj
        } catch (err) {
            console.log("err", err)
            commit("set_error", {
                message:
                    err.message ||
                    "No Internet connection while signup, try again"
            })
        }
    },
    async confirmVerifyMobile({ commit }, { mobileNo, code }) {
        try {
            const confirmVerify = await service.apiCallWithUserManagement(
                "/v2/user/verifyMobile",
                { mobileNo, code }
            )
            return confirmVerify
        } catch (err) {
            commit("set_error", {
                message: "No Internet connection, try again"
            })
        }
    },
    async confirmRegistration({ commit }, { mobileNo, code }) {
        try {
            const confirmRegister = await service.apiCallWithUserManagement(
                "/v2/user/verifymobile",
                { mobileNo, code }
            )
            if (
                confirmRegister &&
                confirmRegister.data &&
                confirmRegister.data.status &&
                confirmRegister.data.status === 200
            ) {
                return confirmRegister
            } else {
                commit("set_error", {
                    message: confirmRegister.data.error.data
                })
                return confirmRegister
            }
        } catch (err) {
            commit("set_error", { message: "Failed to send OTP !!!" })
        }
    },
    async login(
        { commit, dispatch },
        { userName, password, countryCode, captcha }
    ) {
        if (process.env.setUp && process.env.setUp === "fps") {
            countryCode = "91"
        }
        try {
            localStorage.removeItem("accessToken")
            const userObj = {
                userName,
                password,
                countryCode: "+" + countryCode,
                captcha
            }
            const user = await service.apiCallWithUserManagement(
                "/v2/user/login",
                userObj
            )
            if (user && user.data && user.status && user.status === 200) {
                sessionStorage.setItem("loginTs", this.$moment())
                // dispatch("storePL/checkStoreEnabled", null, {
                //     root: true
                // })
            }
            return user
            // if (user && user.data && user.data.value) {
            //   commit('set', user.data)
            //   sessionStorage.setItem('loginTs', this.$moment())
            //   commit('set_login_status', true)
            //   return user.data
            // } else if (user && user.data && user.data.data) {
            //   commit('set_error', { message: user.data.data })
            //   if (user.data.data === 'User is not confirmed') {
            //     return user
            //   }
            // } else {
            //   commit('set_error', { message: user })
            // }
        } catch (err) {
            commit("set_error", {
                message:
                    err.message ||
                    "No Internet connection while login, try again"
            })
        }
    },
    async loginAnotherDomain(
        { commit },
        { userName, password, countryCode, captcha }
    ) {
        try {
            const userObj = {
                userName,
                password,
                countryCode: "+" + countryCode,
                captcha
            }
            const user =
                await service.apiCallWithUserManagementForAnotherDomain(
                    "/v2/user/login",
                    userObj
                )
            if (user && user.data && user.status && user.status === 200) {
                sessionStorage.setItem("loginTs", this.$moment())
            }
            return user
            // if (user && user.data && user.data.value) {
            //   commit('set', user.data)
            //   sessionStorage.setItem('loginTs', this.$moment())
            //   commit('set_login_status', true)
            //   return user.data
            // } else if (user && user.data && user.data.data) {
            //   commit('set_error', { message: user.data.data })
            //   if (user.data.data === 'User is not confirmed') {
            //     return user
            //   }
            // } else {
            //   commit('set_error', { message: user })
            // }
        } catch (err) {
            commit("set_error", {
                message:
                    err.message ||
                    "No Internet connection while login, try again"
            })
        }
    },
    async resendOtpmobileForSignup({ commit }, { mobileNo, userName }) {
        const userObj = {
            mobileNo
            // email: userName
        }
        try {
            const valueChanged = await service.apiCallWithUserManagement(
                "/v2/user/resendotp",
                userObj
            )
            return valueChanged
        } catch (err) {
            commit("set_error", { message: err.message })
        }
    },
    async letsdosomethingwierd({ commit }) {
        try {
            const res = await service.apiCallWithHeader2(
                "/v2/letsdosomethingwierd"
            )
            return res
        } catch (err) {
            commit("set_error", { message: err.message })
        }
    },

    async resendCodeForOtp({ commit }, { mobileNo }) {
        try {
            const valueChanged = await service.apiCallWithUserManagement(
                "/v2/user/resendotp",
                { mobileNo }
            )
            return valueChanged
        } catch (err) {
            commit("set_error", { message: err.message })
        }
    },
    async resetPassword(
        { commit },
        { mobileNo, code, newPassword, userName, countryCode }
    ) {
        const userObj = {
            code,
            mobileNo,
            password: newPassword,
            email: userName,
            countryCode
        }
        try {
            const changedPassword = await service.apiCallWithUserManagement(
                "/User/forgotPasswordVerification",
                userObj
            )
            return changedPassword
            // if (
            //   changedPassword &&
            //   changedPassword.data &&
            //   changedPassword.data.status &&
            //   changedPassword.data.status !== 200 &&
            //   changedPassword.data.data
            // ) {
            //   commit('set_error', { message: changedPassword.data.data })
            // }
        } catch (err) {
            commit("set_error", {
                message:
                    err.message ||
                    "No Internet Connection while reset password, try again"
            })
        }
    },
    async changePassword(
        { commit },
        { accessToken, oldPassword, newPassword }
    ) {
        try {
            const valueChanged = await service.apiCallWithUserManagement(
                "/User/changePassword",
                {
                    accessToken,
                    oldPassword,
                    newPassword
                }
            )
            if (valueChanged.data.data === "update") {
                return valueChanged
            } else {
                commit("set_error", { message: valueChanged.data.data })
            }
        } catch (err) {
            commit("set_error", {
                message:
                    err.message ||
                    "No Internet Connection while changing password, try again"
            })
        }
    },
    async forgotPassword({ commit }, { userName, countryCode }) {
        try {
            const userObj = {
                userName,
                countryCode
            }
            const forgotPasswordNo = await service.apiCallWithUserManagement(
                "/v2/user/forgotpassword",
                userObj
            )
            // console.log('userObj>>>>', userObj)
            return forgotPasswordNo
        } catch (err) {
            commit("set_error", {
                message:
                    err.message ||
                    "No Internet Connection while forgot password, try again"
            })
        }
    },
    async s2sRegistrationPixel({ commit }, s2surl) {
        try {
            const valueChanged = await service.apiCallWithHeader(
                "/v2/s2sRegistration",
                s2surl
            )
            return valueChanged
        } catch (err) {
            commit("set_error", { message: err.message })
        }
    }
}
