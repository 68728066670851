import isEmpty from "lodash/isEmpty"
import groupBy from "lodash/groupBy"
import orderBy from "lodash/orderBy"
export const state = () => ({
    games: [],
    subGames: [],
    loading: false,
    betLoading: false,
    viewMore: true,
    error: {},
    isEmpty: false,
    gameData: [],
    bettingPldata: [],
    lastAccountId: "",
    lastCreatedAt: "",
    gameWiseNetProfitData: [],
    totalPL: 0,
    netCommission: 0,
    eventTypeData: [],
    //
    eventType: "",
    event: "",
    market: "",
    isCardGame: "",
    commissionAmt: 0,
    CommissionTurnover: 0,
    bookmakerResultCommissionAmt: 0,
    type1: 0,
    type2: 0,
    // eventBetsData: [],
    marketSubTotal: 0,
    netMarketTotal: 0,
    stakeadd: 0,
    turnover: 0,
    account: {},
    accounts: []
})

export const mutations = {
    empty_data(state) {
        // state.games = []
        state.subGames = []
        state.loading = false
        state.betLoading = false
        state.error = {}
        state.gameData = []
        state.bettingPldata = []
        state.gameWiseNetProfitData = []
        state.totalPL = 0
        state.netCommission = 0
        state.eventTypeData = []
        state.eventType = ""
        state.event = ""
        state.market = ""
        state.isCardGame = ""
        state.commissionAmt = 0
        state.CommissionTurnover = 0
        state.bookmakerResultCommissionAmt = 0
        state.playerRate = 0
        state.type1 = 0
        state.type2 = 0
        // state.eventBetsData = []
        state.marketSubTotal = 0
        state.netMarketTotal = 0
        state.stakeadd = 0
        state.account = {}
    },
    // setCommission(state, data) {
    //   state.commissionAmt = data
    // },
    // get game API called integrate
    set_games_data(state, data) {
        // eslint-disable-next-line array-callback-return
        data.data.map((n) => {
            if (
                n.name !== "TeenPatti" &&
                n.name !== "Poker" &&
                n.name !== "Roulette"
            ) {
                return state.gameData.push(n)
            }
        })

        const games = [
            {
                name: "All",
                _id: "All"
            }
        ]
        if (data.value && data.data.length > 0) {
            state.gameData = games.concat(state.gameData)
        }
        state.games = state.gameData
    },
    BettingPL_isEmpty(state, data) {
        state.isEmpty = data
    },
    getEventBets_isEmpty(state, data) {
        state.isEmpty = data
    },
    // get subGame API called integrate
    set_subGames_data(state, data) {
        state.subGames = data.data
    },

    // get subGame API called integrate
    set_view_more_data(state, data) {
        state.viewMore = data
    },

    // get BettingPL API called integrate
    set_BettingPL_data(state, data) {
        state.gameWiseNetProfitData = state.gameWiseNetProfitData.concat(
            data.data.gameWiseNetProfit
        )
        state.gameWiseNetProfitData = state.gameWiseNetProfitData.concat(
            data.data.liveArNetProfit
        )
        state.gameWiseNetProfitData = state.gameWiseNetProfitData.concat(
            data.data.cardGameNetProfit
        )

        state.gameWiseNetProfitData = orderBy(
            state.gameWiseNetProfitData,
            ["sequence"],
            ["asc"]
        )

        if (data.value) {
            // use for totalpl data
            state.totalPL = data.data.netProfit
            state.netCommission = data.data.netCommission

            // use for event games data
            state.eventTypeData = data.data.gameWiseNetProfit

            if (!isEmpty(data.data.accounts)) {
                state.accounts = data.data.accounts
                const accountLength = data.data.accounts.length - 1
                state.lastAccountId = data.data.accounts[accountLength]._id
                state.lastCreatedAt =
                    data.data.accounts[accountLength].createdAt
                // eslint-disable-next-line array-callback-return
                data.data.accounts.map((account) => {
                    const newAccount = account
                    newAccount.CommissionAmt = newAccount.commissionAmt
                })
                data.data.accounts = groupBy(data.data.accounts, (account) => {
                    return this.$moment(account.createdAt)
                        .startOf("day")
                        .format()
                })

                // use for group date array
                const dateArray = Object.keys(data.data.accounts).sort(
                    (obj1, obj2) => {
                        const dateA = new Date(obj1)
                        const dateB = new Date(obj2)
                        return dateB - dateA
                    }
                )
                const sortedDate = {}
                // eslint-disable-next-line array-callback-return
                dateArray.map((date) => {
                    sortedDate[date] = data.data.accounts[date]
                })
                state.bettingPldata = sortedDate
                console.log("sortedDtae", sortedDate)
            } else {
                state.bettingPldata = []
            }
        } else {
            state.totalPL = 0
            state.netCommission = 0
            state.eventTypeData = []
        }
    },

    // get getBets API called integrate
    set_getBets_data(state, data) {
        state.account = data.data
        state.account.bets = orderBy(
            state.account.bets,
            ["placedDate"],
            ["desc"]
        )
        state.type1 = state.account.backSubTotal
        state.type2 = state.account.laySubTotal
        state.turnover = state.account.turnover
        state.commissionAmt = state.account.commissionAmt
        state.CommissionTurnover = state.account.turnOverForFancy
        state.bookmakerResultCommissionAmt =
            state.account.playerBookmakerResultCommissionAmt
        state.netMarketTotal = state.account.netMarketTotal
        state.marketSubTotal = state.account.marketSubTotal
        state.marketSubGame = state.account.subGame
    },

    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}

export const actions = {
    // get game API called
    async refreshGames({ commit }) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "Game/getGames",
                data: {
                    _accessToken: this.$auth.getToken("customLocal")
                }
            })
            commit("set_games_data", response.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },

    // get subGame API called
    async refreshSubGames({ commit }, payload) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "Account/getSubGamesFrontend",
                data: {
                    game: payload._id,
                    fromDate: payload.fromDate,
                    toDate: payload.toDate,
                    _accessToken: this.$auth.getToken("customLocal")
                }
            })
            commit("set_subGames_data", response.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },

    // get bettingPl API called
    async search({ commit }, payload) {
        try {
            commit("set_loading", true)
            commit("set_error", "")
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USERS_API_URL,
                url: "sportsbook/bettingPLSnowflake",
                data: {
                    fromDate: payload.fromDate,
                    toDate: payload.toDate,
                    games: payload.game,
                    memberId: this.$auth.user._id,
                    game: payload.game._id,
                    subGame: payload.subGame,
                    subGameFilter: payload.subGame,
                    isCardGame: payload.isCardGame,
                    isLiveAr: payload.isLiveAr,
                    lastId: payload.lastAccountId,
                    createdAt: payload.lastCreatedAt
                }
            })
            // if (
            //   response &&
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.accounts &&
            //   response.data.data.accounts.length
            // ) {
            //   commit('BettingPL_isEmpty', false)
            // } else {
            //   commit('BettingPL_isEmpty', true)
            // }
            commit("set_view_more_data", true)
            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.accounts &&
                response.data.data.accounts.length < 20
            ) {
                commit("set_view_more_data", false)
            }
            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.accounts &&
                payload.lastAccountId &&
                payload.lastCreatedAt
            ) {
                const accountsData = {
                    data: {
                        accounts: [
                            ...payload.accounts,
                            ...response.data.data.accounts
                        ],
                        cardGameNetProfit: response.data.data.cardGameNetProfit,
                        deposit: response.data.data.deposit,
                        gameWiseNetProfit: response.data.data.gameWiseNetProfit,
                        liveArNetProfit: response.data.data.liveArNetProfit,
                        netCommission: response.data.data.netCommission,
                        netProfit: response.data.data.netProfit,
                        premiumSportsbookNetProfit:
                            response.data.data.premiumSportsbookNetProfit,
                        withdrawal: response.data.data.withdrawal
                    },
                    value: response.data.value
                }
                commit("BettingPL_isEmpty", false)
                commit("set_BettingPL_data", accountsData)
            } else if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.accounts &&
                response.data.data.accounts.length > 0
            ) {
                commit("BettingPL_isEmpty", false)
                commit("set_BettingPL_data", response.data)
            } else {
                commit("BettingPL_isEmpty", true)
                commit("set_BettingPL_data", response.data)
            }

            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },

    // get getbets API called
    async getEventBetsDetails({ commit }, payload) {
        try {
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "Account/getOneAccount",
                data: {
                    _id: payload._id,
                    _accessToken: this.$auth.getToken("customLocal")
                }
            })
            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.bets.length
            ) {
                commit("BettingPL_isEmpty", false)
            } else {
                commit("BettingPL_isEmpty", true)
            }
            commit("set_getBets_data", response.data)
        } catch (error) {
            commit("set_error", error)
        }
    }
}
