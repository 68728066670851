export default function ({ store, params, route, redirect, env, $auth }) {
    if (process.client) {
        if (env.setUp && env.setUp === "fps") {
            const element = document.querySelector("#fc_frame")
            if (element && element.classList) {
                element.classList.add("d-none")
                element.classList.add("fc_widgets")
            }
            const callelement = document.querySelector("#__webcallback")
            if (callelement && callelement.classList) {
                callelement.classList.add("d-none")
            }
        } else if (
            !$auth.loggedIn &&
            route.name !== "quick-signup" &&
            route.name !== "fast-signup" &&
            route.name !== "dglp" &&
            route.name !== "register" &&
            route.name !== "registration" &&
            route.name !== "registration2" &&
            route.name !== "region-block" &&
            route.name !== "registration3"
        ) {
            const element = document.querySelector("#fc_frame")
            if (element && element.classList) {
                element.classList.remove("d-none")
                if (element.style) {
                    element.style.zIndex = 10
                }
            }
            const callelement = document.querySelector("#__webcallback")
            if (callelement && callelement.classList) {
                callelement.classList.remove("d-none")
            }
            if (route.path !== "/") {
                const classname = document.getElementById(
                    "mobile-chat-container"
                )
                if (classname && classname.classList) {
                    classname.classList.remove("mobile-chat-container")
                    classname.classList.add("d-none")
                }
            } else {
                const classname = document.getElementById(
                    "mobile-chat-container"
                )
                if (classname && classname.classList) {
                    classname.classList.remove("d-none")
                    classname.classList.add("mobile-chat-container")
                }
            }
            // window.Intercom("hide")
        } else if (
            route.name === "inplay" ||
            route.name === "/" ||
            route.name === "index"
        ) {
            // const classChat = document.getElementsByClassName(
            //     "intercom-lightweight-app"
            // )
            // const check = classChat[0]
            // if (check && check.classList) {
            //     check.classList.remove("d-none")
            // }
            // const classId = document.getElementById("intercom-container")
            // if (classId && classId.classList) {
            //     classId.classList.remove("d-none")
            // }
            // window.Intercom("hide")
            const classname = document.getElementById("mobile-chat-container")
            if (classname && classname.classList) {
                classname.classList.add("mobile-chat-container")
                classname.classList.remove("d-none")
            }
            const element = document.querySelector("#fc_frame")

            if (element && element.classList) {
                element.classList.remove("d-none")
                element.classList.add("fc_widgets")
                element.classList.add("fc_widgets-left")
            }
            const callelement = document.querySelector("#__webcallback")
            if (callelement && callelement.classList) {
                callelement.classList.remove("d-none")
            }
        } else {
            // const classChat = document.getElementsByClassName(
            //     "intercom-lightweight-app"
            // )
            // const check = classChat[0]

            // if (check && check.classList) {
            //     check.classList.add("d-none")
            // }
            // const classId = document.getElementById("intercom-container")
            // if (classId && classId.classList) {
            //     classId.classList.add("d-none")
            // }
            const classname = document.getElementById("mobile-chat-container")
            if (classname && classname.classList) {
                classname.classList.remove("mobile-chat-container")
                classname.classList.add("d-none")
            }
            const element = document.querySelector("#fc_frame")
            if (element && element.classList) {
                element.classList.add("d-none")
                element.classList.add("fc_widgets")
            }
            const callelement = document.querySelector("#__webcallback")
            if (callelement && callelement.classList) {
                callelement.classList.add("d-none")
            }
        }
    }
}
