import encryptor from "~/plugins/encryption.js"

export const state = () => ({
    gameConfigData: {},
    loading: false,
    isBottomDrawerOpen: false,
    error: {}
})

export const mutations = {
    setgameConfigData(state, data) {
        state.gameConfigData.onlyDesktopNote = data
    },
    set_data(state, data) {
        state.gameConfigData = data.results[0]
        // console.log(state.gameConfigData)
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_isBottomDrawerOpen(state, data) {
        state.isBottomDrawerOpen = data
    }
}

export const actions = {
    async getGameConfig({ commit }) {
        // This will stored data for 10mins.
        if (
            localStorage.getItem("set_Notification_timezone") &&
            localStorage.getItem("set_Notification")
        ) {
            const aa = localStorage.getItem("set_Notification_timezone")
            const now = new Date().getTime()
            const distance = now - aa
            const seconds = Math.floor(distance / 1000)
            const minutes = Math.floor(seconds / 60)
            if (minutes >= 10) {
                localStorage.removeItem("set_Notification")
                localStorage.removeItem("set_Notification_timezone")
            }
        }
        if (process.client && localStorage.getItem("set_Notification")) {
            const data = await JSON.parse(
                encryptor.decrypt(localStorage.getItem("set_Notification"))
            )
            commit("set_data", data)
            return
        }

        try {
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.CONFIG_V2_API_URL,
                url: "GameConfig/search",
                data: {}
            })
            if (response.data.value) {
                commit("set_data", response.data.data)
                localStorage.setItem(
                    "set_Notification",
                    encryptor.encrypt(JSON.stringify(response.data.data))
                )
                localStorage.setItem(
                    "set_Notification_timezone",
                    new Date().getTime()
                )
            }
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}

export const getters = {
    gameConfigData: (state) => state.gameConfigData
}
