import io from "socket.io-client"
import ClienthostName from "./hostname"
const options = {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionDelayMax: 500,
    reconnectionAttempts: Infinity,
    forceNew: true,
    transports: ["websocket"],
    timeout: 10000
}
// const hostname = process.env.dynamicDomain
//     ? "localhost"
//     : window.location.hostname
// const mainDomainLink =
//     process.env.fairplayEnvironment === "PROD"
//         ? "fariplay.com"
//         : hostname && hostname === "localhost"
//         ? process.env.basicDomain
//         : hostname &&
//           (hostname.includes("m.") ||
//               hostname.includes("bd.") ||
//               hostname.includes("www.") ||
//               hostname.includes("fantasy."))
//         ? hostname.replace(/m.|www.|bd.|fantasy./gi, "")
//         : hostname
let mainDomainLink = ""
if (process.client) {
    mainDomainLink = ClienthostName.DomainCheck(window.location.hostname)
}

const socket1 = io(process.env.fairplaySocketUrl + mainDomainLink, options)

export default socket1
