import service from "@/services/fbApiService"

export const state = () => ({
    matchIds: [],
    bettingType: "",
    marketData: [],
    runner: [],
    market: [],
    allRunners: [],
    placeBetJSON: {},
    odds: 0,
    runs: 0,
    position: 0,
    oddsIndex: 0,
    type: "",
    loading: false,
    error: "",
    warning: "",
    marker3etId: null,
    success: "",
    error_string: "",
    fancy: Boolean,
    betSlipRunnerForMatchOdds: {},
    betSlipRunnerForCustomFancy: {},
    betSlipRunnerForPremiumSports: {},
    color: "",
    matchOdds: 0
})
export const mutations = {
    set_matchOdds(state, { matchOdds, color, bettingType, allRunners }) {
        state.allRunners = allRunners
        state.matchOdds = matchOdds
        state.color = color
        state.bettingType = bettingType
    },
    openPopover(
        state,
        {
            market,
            marketData,
            runner,
            allRunners,
            odds,
            oddsIndex,
            type,
            bettingType,
            runs,
            matchOdds,
            betSlipRunnerForMatchOdds,
            betSlipRunnerForCustomFancy,
            betSlipRunnerForPremiumSports,
            color,
            fancy
        }
    ) {
        // console.log({ marketData, runner, allRunners, odds, type, bettingType, runs })
        // if (odds !== undefined) {
        //     state.market = market
        //     state.marketData = marketData
        //     state.runner = runner
        //     state.allRunners = allRunners
        //     state.odds = odds
        //     state.oddsIndex = oddsIndex
        //     state.runs = runs
        //     state.type = type
        //     state.bettingType = bettingType
        // } else {
        //     let error
        //     if (odds !== undefined) {
        //         error = "Odds are not there"
        //     }
        //     state.error = error
        //     this.commit("snackbar/open", {
        //         text: state.error,
        //         color: "error"
        //     })
        // }
        state.market = market
        state.marketData = marketData
        state.runner = runner
        state.allRunners = allRunners
        state.odds = odds
        state.oddsIndex = oddsIndex
        state.runs = runs
        state.type = type
        state.bettingType = bettingType
        state.matchOdds = matchOdds
        state.betSlipRunnerForMatchOdds = betSlipRunnerForMatchOdds
        state.betSlipRunnerForCustomFancy = betSlipRunnerForCustomFancy
        state.betSlipRunnerForPremiumSports = betSlipRunnerForPremiumSports
        state.color = color
        state.fancy = fancy
    },
    reset_popover(state) {
        state.marketData = []
        state.runner = []
        state.market = []
        state.allRunners = []
        state.odds = 0
        state.oddsIndex = 0
        state.runs = 0
        state.type = ""
        state.bettingType = ""
        state.matchOdds = 0
        state.betSlipRunnerForMatchOdds = {}
        state.betSlipRunnerForCustomFancy = {}
        state.betSlipRunnerForPremiumSports = {}
        state.color = ""
        state.fancy = Boolean
    },
    reset_success(state) {
        state.success = ""
    },
    reset_error(state) {
        state.error = ""
    },
    reset_error_string(state) {
        state.error_string = ""
    },
    set_success(state, data) {
        state.success = data
    },
    set_error(state, data) {
        state.error = data
    },
    set_error_string(state, data) {
        state.error_string = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}
export const actions = {
    async execute({ commit, state }, data) {
        try {
            // console.log("inside store execute")
            commit("set_loading", true)
            commit("reset_error")
            commit("reset_success")
            commit("reset_error_string")
            let URL
            if (data.betType === "fancy-market-custom") {
                URL = "/v2/placePlayerBetNewForCustomFancy"
            } else if (data.betType === "odds") {
                URL = "/v2/placeNewBet"
            } else {
                URL = "/v2/UOFPlaceNewBet"
            }

            const placeBetOutput = await service.apiCallWithDataForPlaceBet(
                URL,
                data
            )
            if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.status &&
                placeBetOutput.data.status === 200 &&
                placeBetOutput.data.data
            ) {
                if (placeBetOutput.data.data.message) {
                    commit("set_success", placeBetOutput.data.data.message)
                }
            } else if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.status &&
                placeBetOutput.data.status === "EXECUTABLE"
            ) {
                commit("set_error", placeBetOutput.data.status)
            } else if (
                placeBetOutput &&
                placeBetOutput.status &&
                placeBetOutput.status === 204
            ) {
                commit("set_error", placeBetOutput.data.status)
            } else if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.error &&
                placeBetOutput.data.error.err &&
                placeBetOutput.data.error.err ===
                    "Unable to Place Bet as Market is Suspended or Closed"
            ) {
                commit("set_error", placeBetOutput.data.error.err)
            } else if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.error &&
                placeBetOutput.data.error.err &&
                placeBetOutput.data.error.err === "Rates changed"
            ) {
                commit("set_error", placeBetOutput.data.error.message)
            } else if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.error &&
                placeBetOutput.data.value === false
            ) {
                commit("set_error", placeBetOutput.data.value)
            } else if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.error &&
                placeBetOutput.data.error.errors &&
                placeBetOutput.data.error.errors[0] &&
                placeBetOutput.data.error.errors[0].msg
            ) {
                commit("set_error", placeBetOutput.data.error.errors[0].msg)
            }
            // else {
            //     // this.showSnackbar({
            //     //     content: "Retry Fantasy Pro Again",
            //     //     color: "error"
            //     // })
            // }
            else if (
                placeBetOutput &&
                placeBetOutput.error &&
                placeBetOutput.error === "MIN_BET_STAKE_REQUIRED"
            ) {
                commit("set_error", placeBetOutput.error)
            } else if (
                placeBetOutput &&
                placeBetOutput.error &&
                placeBetOutput.error === "accessDenied"
            ) {
                commit("set_error", placeBetOutput.error)
            } else if (
                (placeBetOutput &&
                    placeBetOutput.error &&
                    placeBetOutput.error === "No match found") ||
                (placeBetOutput &&
                    placeBetOutput.error &&
                    placeBetOutput.error === "no match found 1") ||
                (placeBetOutput &&
                    placeBetOutput.error &&
                    placeBetOutput.error === "no match found 2") ||
                (placeBetOutput &&
                    placeBetOutput.error &&
                    placeBetOutput.error === "no match found 3") ||
                (placeBetOutput &&
                    placeBetOutput.error &&
                    placeBetOutput.error === "no match found 4")
            ) {
                commit("set_error", placeBetOutput.error)
            } else if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.error &&
                placeBetOutput.data.error === "Insufficient credit limit"
            ) {
                commit("set_error", placeBetOutput.data.error)
            } else if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.error &&
                placeBetOutput.data.error ===
                    "Stake Amount Should Be Greater Than Zero"
            ) {
                commit("set_error", placeBetOutput.data.error)
            } else if (
                placeBetOutput &&
                placeBetOutput.error &&
                placeBetOutput.error === "Exceeded the profit limit"
            ) {
                commit("set_error", placeBetOutput.error)
            } else if (
                placeBetOutput &&
                placeBetOutput.error &&
                placeBetOutput.error === "Exceeded the Max Market limit"
            ) {
                commit("set_error", placeBetOutput.error)
            } else if (
                placeBetOutput &&
                placeBetOutput.error &&
                placeBetOutput.error === "Previous Bet In Process"
            ) {
                commit("set_error", placeBetOutput.error)
            } else if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.error &&
                placeBetOutput.data.error.err &&
                placeBetOutput.data.error.err ===
                    "Unable to Place Bet as Market is Suspended or Closed"
            ) {
                commit("set_error", placeBetOutput.data.error.err)
            } else if (
                placeBetOutput &&
                placeBetOutput.data &&
                placeBetOutput.data.error
            ) {
                if (typeof placeBetOutput.data.error === "string") {
                    commit("set_error_string", placeBetOutput.data.error)
                } else {
                    for (const x in placeBetOutput.data.error) {
                        commit("set_error_string", placeBetOutput.data.error[x])
                    }
                }
            }
            commit("set_loading", false)
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data === "upstream request timeout"
            ) {
                let message = ""
                message = "Error while placing bet"
                commit("set_error", message)
            } else {
                commit("set_error", error)
            }
            commit("set_loading", false)
        }
    }
}
