export default function ({ env, redirect, route }) {
    if (env.setUp && env.setUp !== "fps" && route.name === "book-register") {
        return redirect("/")
    } else if (env.setUp && env.setUp === "fps") {
        if (route.name === "register" || route.name === "forgot-password") {
            return redirect("/login")
        } else if (
            route.name === "wallet-deposit" ||
            route.name === "wallet-instant-withdrawal" ||
            route.name === "wallet-withdrawal" ||
            route.name === "quick-signup" ||
            route.name === "fast-signup" ||
            route.name === "affiliate-partner" ||
            route.name === "online-betting-app" ||
            route.name === "registration" ||
            route.name === "user-registration" ||
            route.name === "registration2" ||
            route.name === "registration3" ||
            route.name === "quick-signup-2" ||
            route.name === "change-mobileno" ||
            route.name === "register" ||
            route.name === "dglp" ||
            route.name === "user-book" ||
            route.name === "check-in" ||
            route.name === "quick-checkin" ||
            route.name === "enroll-now" ||
            route.name === "quick-enroll" ||
            route.name === "blog"
        ) {
            return redirect("/")
        }
    }
}
