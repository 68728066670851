/* eslint-disable prettier/prettier */
export const state = () => ({
  notificationData: [],
  loading: false,
  totalPage: null,
  error: {}
})

export const mutations = {
  empty_data(state) {
    state.notificationData = []
  },
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_data(state, data) {
    state.notificationData = data.dataArray
    state.totalPage = data.noOfPages
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getNotification({ commit }, payload) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
          method: "post",
          baseURL: process.env.USERS_API_URL,
          url: "Notification/getNotifications",
          data: {
              page: payload
          }
      })
      if (
        response.data.data.dataArray &&
        response.data.data.dataArray.length > 0
      ) {
        commit('set_isEmpty', false)
      } else {
        commit('set_isEmpty', true)
      }

      if (response.data.value) {
        commit('set_data', response.data.data)
      }

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
