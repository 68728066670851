import html2canvas from "html2canvas"
export const state = () => ({
    image: undefined,
    text: undefined,
    files: undefined,
    url: undefined,
    error: {},
    modalOpen: false
})

export const getters = {
    modalOpen: (state) => state.modalOpen
}

export const mutations = {
    set_modal(state, data) {
        state.modalOpen = data
    },
    set_data(state, { canvas, text, files, url }) {
        state.image = canvas.toDataURL()
        state.text = text
        state.url = url
        state.files = files
    },
    set_reset(state) {
        state.text = undefined
        state.image = undefined
        state.url = undefined
        state.files = undefined
    },
    set_error(state, data) {
        state.error = data
    }
}

export const actions = {
    share({ state }) {
        if (navigator.share && state.url) {
            navigator.share({
                text: state.text,
                files: state.files,
                url: state.url
            })
        } else if (navigator.share && !state.url) {
            navigator.share({
                text: state.text,
                files: state.files
            })
        }
    },
    async open({ commit }, { ref, text, url = window.location.href }) {
        try {
            commit("set_reset")
            commit("set_modal", true)
            const fileType = "image/jpeg"
            const fileName = "picture.jpeg"
            const data = await html2canvas(ref)
            data.toBlob((blob) => {
                const file = new File([blob], fileName, { type: fileType })
                const filesArray = [file]
                commit("set_data", {
                    canvas: data,
                    text,
                    files: filesArray,
                    url
                })
            }, fileType)
        } catch (error) {
            commit("set_error", error)
        }
    },
    async saveShareStatus(
        { commit },
        { ref, text, url = window.location.href }
    ) {
        try {
            commit("set_reset")
            const fileType = "image/jpeg"
            const fileName = "picture.jpeg"
            const data = await html2canvas(ref)
            data.toBlob((blob) => {
                const file = new File([blob], fileName, { type: fileType })
                const filesArray = [file]
                commit("set_data", {
                    canvas: data,
                    text,
                    files: filesArray,
                    url
                })
            }, fileType)
        } catch (error) {
            commit("set_error", error)
        }
    }
}
