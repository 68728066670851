import groupBy from "lodash/groupBy"
import isEmpty from "lodash/isEmpty"
import service from "~/services/apiService"

export const state = () => ({
    loading: false,
    viewMore: true,
    error: {},
    isEmpty: false,
    storePldata: [],
    pageNo: 1,
    limitNo: 10,
    isStoreEnabled: false
})

export const mutations = {
    empty_data(state) {
        state.loading = state.viewMore = false
        state.isEmpty = true
        state.error = {}
        state.storePldata = []
    },
    BettingPL_isEmpty(state, data) {
        state.isEmpty = data
    },
    set_view_more_data(state, data) {
        state.viewMore = data
    },
    set_StorePL_data(state, data) {
        if (data) {
            if (!isEmpty(data)) {
                state.storePldata = data
            } else {
                state.storePldata = []
            }
        }
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_LimitNo(state, data) {
        state.limitNo += data
    },
    set_DefaultLimitNo(state, data) {
        state.limitNo = data
    },
    set_store_enabled(state, data) {
        state.isStoreEnabled = data
    }
}

export const actions = {
    async search({ state, commit }, payload) {
        try {
            commit("set_loading", true)
            commit("set_error", "")
            const response = await service.apiCallWithHeader2(
                "/v2/getUserPNLRewardPointList",
                payload
            )

            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.paginatedResults &&
                response.data.data.paginatedResults.length
            ) {
                commit("BettingPL_isEmpty", false)
            } else {
                commit("BettingPL_isEmpty", true)
            }
            commit("set_view_more_data", true)
            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.totalCount &&
                response.data.data.totalCount <= 10
            ) {
                commit("set_view_more_data", false)
            }

            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.paginatedResults &&
                response.data.data.paginatedResults.length > 0
            ) {
                commit("BettingPL_isEmpty", false)

                const groupedPNL = groupBy(
                    response.data.data.paginatedResults,
                    (d) => {
                        return this.$moment(d.createdAt).startOf("day").format()
                    }
                )

                commit("set_StorePL_data", groupedPNL)
            } else {
                commit("BettingPL_isEmpty", true)
                commit("set_StorePL_data", [])
            }

            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.paginatedResults &&
                response.data.data.paginatedResults.length > 0 &&
                response.data.data.totalCount > 0 &&
                response.data.data.count !== response.data.data.totalCount
            ) {
                commit("set_LimitNo", 10)
            } else {
                commit("set_view_more_data", false)
                commit("set_LimitNo", 10)
            }

            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async checkStoreEnabled({ commit }) {
        try {
            if (process.env.STORE_API) {
                const storeEnabled = await service.getStoreEnabled(
                    "/v2/getConfiguration"
                )
                if (
                    storeEnabled &&
                    storeEnabled.data &&
                    storeEnabled.data.data &&
                    storeEnabled.data.status &&
                    storeEnabled.data.status === 200 &&
                    "isStorePortalAvailable" in storeEnabled.data.data
                ) {
                    commit(
                        "set_store_enabled",
                        storeEnabled.data.data.isStorePortalAvailable
                    )
                } else {
                    commit("set_store_enabled", false)
                }
            }
        } catch (err) {
            commit("SET_ERROR", {
                message: err.message || "Something went wrong"
            })
            commit("set_store_enabled", false)
        }
    }
}
