import service from "../services/apiService"

export const state = () => ({})

export const actions = {
    async getPaymentGatewayLink(_, data) {
        try {
            const res = await service.apiCallWithHeader(
                "/PaymentGateway/cashfree/createOrder",
                data
            )
            return res
        } catch (err) {}
    },
    async payWithEchelonPay(_, data) {
        try {
            const res = await service.apiCallWithHeader2(
                "/v2/echelonpayService/initiatePayment",
                data
            )
            return res
        } catch (err) {
            return err
        }
    },
    async payWithRush(_, data) {
        try {
            const res = await service.apiCallWithHeader2(
                "/v2/rushpay/initiatePayment",
                data
            )
            return res
        } catch (err) {
            return err
        }
    },

    async payWithPaymentGateway(_, data) {
        try {
            const res = await service.apiCallWithHeader2(
                "/v2/PaymentGateway/checkout",
                data
            )
            return res
        } catch (err) {
            return err
        }
    },
    async getOrderId() {
        try {
            const res = await service.apiCallWithHeader(
                "/Transaction/getNextOrderId"
            )
            return res
        } catch (err) {
            return err
        }
    },
    async saveTransaction(_, data) {
        try {
            await service.apiCallWithHeader(
                "/BankDetail/paymentgateway/success"
            )
        } catch (err) {}
    },
    async proccedWithPaymentGateway(_, data) {
        try {
            const res = await service.apiCallWithPaymentGateway(
                "/v2/payWithPaymentGateway",
                data
            )
            return res
        } catch (err) {
            return err
        }
    },
    async proccedWithCrypto() {
        try {
            const res = await service.apiCallWithHeaderUsingGet(
                "/v2/proccedWithCrypto"
            )
            return res
        } catch (err) {
            return err
        }
    }
}
