export const state = () => ({
    value: false
    // popupTimer: 30,
    // logoutTimer: 60,
    // idleInterval: null
})

export const mutations = {
    toggle(state, data) {
        state.value = data
    }
}

export const getters = {
    value: (state) => state.value
}
