export const state = () => ({
    adminWacUrl: undefined,
    loading: false,
    allGamesData: [],
    wacGameData: [],
    wacsubGameData: [],
    wacGameUrl: [],
    session: {},
    error: {},
    casinoGames: [],
    providerDatas: [],
    casinoIframeURL: "",
    gameList: [],
    getGameUrl: [],
    oneGameData: {}
})

export const mutations = {
    // casino sub-games api
    set_game(state, data) {
        state.casinoGames = data
    },

    // casino sub-games iframe Url api
    set_game_url(state, data) {
        state.casinoIframeURL = data.launch_request_url
    },

    // live casino iframe apis
    set_data(state, data) {
        if (data) {
            state.adminWacUrl =
                process.env.WAC_URL +
                "/game.do?pn=" +
                data.pn +
                "&lang=" +
                data.lang +
                "&game=" +
                data.game +
                "&type=" +
                data.type +
                "&token=" +
                data.sessionId
        }
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    // wac api data
    set_wacdata(state, data) {
        state.wacGameData = data
    },
    set_wacsubdata(state, data) {
        state.wacsubGameData = data
    },
    set_wacdurl(state, data) {
        state.wacGameUrl = data
    },
    set_wac_session(state, data) {
        state.session = data
    },
    set_all_games(state, data) {
        state.allGamesData = data
    },
    set_one_game(state, data) {
        state.oneGameData = data
    },
    set_providerData(state, data) {
        state.providerDatas = data
    },
    set_game_list(state, data) {
        state.gameList = data
    },
    isMobile() {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            return true
        } else {
            return false
        }
    }
}

export const actions = {
    async getAllGame({ commit }) {
        const _accessToken = this.$auth.getToken("customLocal")
        const pageNo = 1
        await this.$axios({
            method: "post",
            baseURL: process.env.WAC_GAME_API_URL,
            url: `/api/Game/getAllGamePagination`,
            data: {
                page: pageNo,
                _accessToken
            }
        })
            .then((response) => {
                if (response && response.data && response.data.success) {
                    commit("set_all_games", response.data)
                }
                // const sortData = response.data.data.sort((a, b) =>
                //   a.order > b.order ? 1 : -1
                // )

                commit("set_loading", false)
            })
            .catch((error) => {
                commit("set_error", error)
                commit("set_loading", false)
            })
    },
    async getGame({ commit }, providerName) {
        const _accessToken = this.$auth.getToken("customLocal")
        await this.$axios({
            method: "post",
            baseURL: process.env.WAC_GAME_API_URL,
            url: `/api/Game/getAllWacList`,
            data: {
                isGameType: true,
                providerName,
                _accessToken
            }
        })
            .then((response) => {
                if (response && response.success) {
                    commit("set_wacdata", response)
                }
                // const sortData = response.data.data.sort((a, b) =>
                //   a.order > b.order ? 1 : -1
                // )

                commit("set_loading", false)
            })
            .catch((error) => {
                commit("set_error", error)
                commit("set_loading", false)
            })
    },
    async gameUrl({ commit }, { game }) {
        const _accessToken = this.$auth.getToken("customLocal")
        await this.$axios({
            method: "post",
            baseURL: process.env.WAC_GAME_API_URL,
            url: `/api/Game/getAllWacData`,
            data: {
                filter: "gametype",
                providerName: game,
                _accessToken
            }
        })
            .then((response) => {
                if (response && response.data && response.data.success) {
                    commit("set_wacdurl", response.data.data)
                }
                commit("set_loading", false)
            })
            .catch((error) => {
                commit("set_error", error)
                commit("set_loading", false)
            })
    },
    async gamesubGame({ commit }, data) {
        const _accessToken = this.$auth.getToken("customLocal")
        data._accessToken = _accessToken
        data.filter = "subgametype"
        await this.$axios({
            method: "post",
            baseURL: process.env.WAC_GAME_API_URL,
            url: `/api/Game/getAllWacData`,
            data
        })
            .then((response) => {
                commit("set_wacsubdata", response.data.data)
                commit("set_loading", false)
            })
            .catch((error) => {
                commit("set_error", error)
                commit("set_loading", false)
            })
    },
    async getGameList({ commit }, data) {
        const _accessToken = this.$auth.getToken("customLocal")
        data._accessToken = _accessToken
        data.filter = "game"
        await this.$axios({
            method: "post",
            baseURL: process.env.WAC_GAME_API_URL,
            url: `/api/Game/getAllWacData`,
            data
        })
            .then((response) => {
                commit("set_game_list", response.data.data)
                commit("set_loading", false)
            })
            .catch((error) => {
                commit("set_error", error)
                commit("set_loading", false)
            })
    },

    // // live casino iframe api
    // async getWacUrl({ commit }, data) {
    //   commit('set_loading', true)
    //   const _accessToken = this.$auth.getToken('customLocal')
    //   try {
    //     commit('set_loading', true)
    //     const response = await this.$axios({
    //       method: 'post',
    //       baseURL: process.env.WAC_SESSION_URL,
    //       url: `/Sessions/saveWacSession`,
    //       data: {
    //         gameName: data,
    //         _accessToken
    //       }
    //     })
    //     const pair = {
    //       token: response.data.sessionId,
    //       pn: data.pn,
    //       lang: data.lang,
    //       game: response.data.gameName,
    //       type: data.type
    //     }
    //     response.data = { ...response.data, ...pair }
    //     commit('set_data', response.data)
    //     commit('set_loading', false)
    //   } catch (error) {
    //     commit('set_error', error)
    //     commit('set_loading', false)
    //   }
    // },

    // // Wac Game Set User Session
    async wacSession({ commit }) {
        commit("set_loading", true)
        const _accessToken = this.$auth.getToken("customLocal")
        await this.$axios({
            method: "post",
            baseURL: process.env.WAC_SESSION_URL,
            url: `/Sessions/saveWacSession`,
            data: {
                _accessToken
            }
        })
            .then((response) => {
                commit("set_wac_session", response.data)
                commit("set_loading", false)
            })
            .catch((error) => {
                commit("set_error", error)
                commit("set_loading", false)
            })
    },
    async providerData({ commit }) {
        const _accessToken = this.$auth.getToken("customLocal")
        await this.$axios({
            method: "post",
            baseURL: process.env.WAC_GAME_API_URL,
            url: `/api/Game/getAllWacData`,
            data: {
                filter: "provider",
                _accessToken
            }
        })
            .then((response) => {
                // const sortData = response.data.data.sort((a, b) =>
                //   a.order > b.order ? -1 : 1
                // )
                if (response && response.data) {
                    commit("set_providerData", response.data.data)
                }
            })
            .catch((error) => {
                commit("set_error", error)
                commit("set_loading", false)
            })
    },
    async getOneGame({ commit }, data) {
        commit("set_loading", true)
        const _accessToken = this.$auth.getToken("customLocal")
        await this.$axios({
            method: "post",
            baseURL: process.env.WAC_GAME_API_URL,
            url: `/api/Game/getOneGame`,
            data: {
                _id: data,
                _accessToken
            }
        })
            .then((response) => {
                // const sortData = response.data.data.sort((a, b) =>
                //   a.order > b.order ? -1 : 1
                // )
                if (response && response.data && response.data.success) {
                    commit("set_one_game", response.data.data)
                }
            })
            .catch((error) => {
                commit("set_error", error)
                commit("set_loading", false)
            })
    }
}
