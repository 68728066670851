import service from "../services/apiService"
export const state = () => ({
    surveyForm: [],
    error: null
})

export const mutations = {
    SET_SURVEY_FORM(state, data) {
        state.surveyForm = data
    },
    SET_ERROR(state, data) {
        state.error = data
    }
}

export const actions = {
    async userSurvey({ commit }, data) {
        try {
            const response = await service.apiCallWithHeader2(
                "/v2/userSurvey",
                data
            )
            return response
        } catch (err) {
            commit("SET_ERROR", {
                message: "No internet connection, please try again!"
            })
        }
    }
}
