// vuetify.options.js
const colorsDark = {
    primary: process.env.THEME_PRIMARY || "#e15b27",
    info: process.env.THEME_INFO || "#e76f26",
    secondary: "#a5d9fe",
    accent: "#f8d0ce",
    background: process.env.THEME_DARK || "#121212",
    logocolor: process.env.THEME_LOGO_BG || "#121212",
    // superwinTheme start
    superBlue:
        process.env.THEME_BASIC === "true"
            ? "#4caf50"
            : process.env.THEME_SUPER_BLUE,
    // green_bg: process.env.THEME_SUPER_BLUE || "#4caf50",
    greensuccColor: "#4caf50",
    drawerBg: "#005ba9",
    superNetExpo: "#ff0000",
    // superwinTheme end
    // live casino card color start
    baseColor: "#130c2d",
    redcolor:
        process.env.THEME_BASIC === "true"
            ? "#ff0000"
            : process.env.THEME_PRIMARY,
    orangecolor:
        process.env.THEME_BASIC === "true"
            ? "#ffa500"
            : process.env.THEME_PRIMARY,
    pinkishcolor:
        process.env.THEME_BASIC === "true"
            ? "#fab2ff"
            : process.env.THEME_SUPER_BLUE,
    purplecolor:
        process.env.THEME_BASIC === "true"
            ? "#8154f1"
            : process.env.THEME_SUPER_BLUE,
    orangemid:
        process.env.THEME_BASIC === "true"
            ? "#f37415"
            : process.env.THEME_PRIMARY,
    royalBluecolor:
        process.env.THEME_BASIC === "true"
            ? "#1904e5"
            : process.env.THEME_SUPER_BLUE,
    bottomChildColor:
        process.env.THEME_BASIC === "true"
            ? "#4caf50"
            : process.env.THEME_SUPER_BLUE,
    maroonColor:
        process.env.THEME_BASIC === "true"
            ? "#7a3c1d"
            : process.env.THEME_PRIMARY,
    tabIcoColor:
        process.env.THEME_BASIC === "true"
            ? "#6fae44"
            : process.env.THEME_SUPER_BLUE,
    baseThemeColor:
        process.env.THEME_BASIC === "true"
            ? "#f16c21"
            : process.env.THEME_PRIMARY,
    normalThemeColor:
        process.env.THEME_BASIC === "true"
            ? "#f36c20"
            : process.env.THEME_PRIMARY,
    contactColor:
        process.env.THEME_BASIC === "true"
            ? "#ff512f"
            : process.env.THEME_PRIMARY,
    contactOrangeColor:
        process.env.THEME_BASIC === "true"
            ? "#f09819"
            : process.env.THEME_SUPER_BLUE,
    gameColor:
        process.env.THEME_BASIC === "true"
            ? "#5f3225"
            : process.env.THEME_PRIMARY,
    // fantasy
    newTheme: "#69a647",
    lightBackground: "#2e2e2e",
    lightdark: "#121212",
    extradark: "#1e1e1e",
    tabBackground: "#5c5c5c",
    back: "#a5d9fe",
    lay: "#f8d0ce",
    greyBackground: "#e0e0e0",
    lightBlack: "#313131",
    // headerBackground: '#262626',
    headerText: "#262626",
    dividerLight: "#dadada",
    lightGrey: "#e5e5e5"
}
const colorsLight = {
    primary: process.env.THEME_PRIMARY || "#e15b27",
    info: process.env.THEME_INFO || "#e76f26",
    // superwinTheme start
    superBlue: process.env.THEME_SUPER_BLUE || "success",
    green_bg: process.env.THEME_SUPER_BLUE || "#4caf50",
    lCards: process.env.THEME_PRIMARY || "lCards",
    primarybar_bg: process.env.THEME_SUPER_BLUE || "primarybar-bg",
    drawerBg: "#005ba9",
    superNetExpo: "#ff0000",
    // superwinTheme end
    newTheme: "#69a647",
    secondary: "#a5d9fe",
    accent: "#f8d0ce",
    logocolor: process.env.THEME_LOGO_BG || "#121212"
}
export default {
    theme: {
        options: {
            customProperties: true
        },
        dark: true,
        themes: {
            dark: colorsDark,
            light: colorsLight
        }
    }
}
