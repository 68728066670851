import socket1 from "@/plugins/newSocket.client"
import service from "../services/fbApiService.js"
export const state = () => ({
    socketId: undefined,
    matchIds: []
})

export const mutations = {
    RESET_SOCKET_ID(state) {
        state.socketId = undefined
    },
    SET_SOCKET_ID(state, data) {
        state.socketId = data
    },
    addMatchOddsId(state, data) {
        state.matchIds.push(data)
    }
}

export const actions = {
    // Fantasy Configuration Start
    joinFantasyConfigRoom({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (state.socketId) {
                socket1.emit("joinRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },
    leaveFantasyConfigRoom({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (state.socketId) {
                socket1.emit("leaveRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },
    // Fantasy Configuration End

    // Fantasy Fancy Result Start
    joinFancyFantasyRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (this.$auth.loggedIn && state.socketId) {
                socket1.emit("joinRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },
    leaveFancyFantasyRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (this.$auth.loggedIn && state.socketId) {
                socket1.emit("leaveRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },

    // Fantasy Fancy Result End

    // Fantasy Final Result Start
    joinFantasyUOFRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (this.$auth.loggedIn && state.socketId) {
                socket1.emit("joinRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },
    leaveFantasyUOFRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (this.$auth.loggedIn && state.socketId) {
                socket1.emit("leaveRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },

    // Fantasy Final Result End

    // Fantasy UOF Result Start
    joinFinalFantasyRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (this.$auth.loggedIn && state.socketId) {
                socket1.emit("joinRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },
    leaveFinalFantasyRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (this.$auth.loggedIn && state.socketId) {
                socket1.emit("leaveRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },

    // Fantasy UOF Result End

    // Markets ON_OFF SOCKETS START
    joinRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (state.socketId) {
                socket1.emit("joinRoom", {
                    roomName: "homeEnableMarketSocket"
                })
            }
        } catch (error) {}
    },
    leaveRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (state.socketId) {
                socket1.emit("leaveRoom", {
                    roomName: "homeEnableMarketSocket"
                })
            }
        } catch (error) {}
    },
    // Markets ON_OFF SOCKETS END

    // Pool/Contest ON_OFF START
    joinPoolRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (this.$auth.loggedIn && state.socketId) {
                socket1.emit("joinRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },
    leavePoolRoomMarket({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (state.socketId) {
                socket1.emit("leaveRoom", {
                    roomName: socketId.socketName
                })
            }
        } catch (error) {}
    },
    // Pool/Contest ON_OFF END

    joinRoomMaintenenace({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (state.socketId) {
                socket1.emit("joinRoom", {
                    roomName: "getWebisteMaintenance"
                })
            }
        } catch (error) {}
    },
    leaveRoomMaintenenace({ state, commit }, socketId) {
        commit("SET_SOCKET_ID", socketId)
        try {
            if (state.socketId) {
                socket1.emit("leaveRoom", {
                    roomName: "getWebisteMaintenance"
                })
            }
        } catch (error) {}
    },
    async joinRoomBalance(_, data) {
        try {
            await service.apiCallWithFantasySockets(
                `/joinRoom/?roomName=${data.socketName}&socketId=${data.socketId}`
            )
            // socket.adminSocket.emit("joinRoom", { roomNames: data.socketName });
        } catch (err) {
            console.log("inside join room error", err)
        }
    },
    joinRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("joinRoom", {
                    roomName:
                        "changeTransactionStatusFromAdmin-" + socketId.userId
                })
            }
        } catch (error) {}
    },

    leaveRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("leaveRoom", {
                    roomName:
                        "changeTransactionStatusFromAdmin-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    joinRoomLogin({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const accessToken = this.$auth.getToken('customLocal')
                // const userId = this.$auth.user._id
                socket1.emit("joinRoom", {
                    roomName: "logout_" + socketId.userId
                })
            }
        } catch (error) {}
    },

    leaveRoomLogin({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const accessToken = this.$auth.getToken('customLocal')
                // const userId = this.$auth.user._id
                socket1.emit("leaveRoom", {
                    roomName: "logout_" + socketId.userId
                })
            }
        } catch (error) {}
    },
    joinNotificationRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("joinRoom", {
                    roomName: "sendNotificationToUser-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    leaveNotificationRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("leaveRoom", {
                    roomName: "sendNotificationToUser-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    joinBonusRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("joinRoom", {
                    roomName: "userBonus-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    leaveBonusRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("leaveRoom", {
                    roomName: "userBonus-" + socketId.userId
                })
            }
        } catch (error) {}
    }
}
