export const state = () => ({
    loading: false,
    error: {},
    maintenanceStatus: {}
})

export const mutations = {
    set_MaintenanceStatus(state, data) {
        state.maintenanceStatus = data
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}
export const actions = {
    async getMaintenanceStatus({ commit }) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "GET",
                baseURL: process.env.CONFIG_V2_API_URL,
                url: "maintenance/getMaintenanceStatus "
            })
            commit("set_MaintenanceStatus", response.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
