/*eslint-disable*/
// import moment from 'moment'
import isEmpty from "lodash/isEmpty"
import service from "../services/fbApiService"

export const state = () => ({
    loading: false,
    name: "",
    amountToPay: "",
    maximumPeople: "",
    totalContestAmount: "-",
    categoryData: {},
    isFreePool: true,
    privateContestData: false
})

export const mutations = {
    setContestObj(state, contestObj) {
        state.contestToCreate = contestObj
    },
    setContestName(state, name) {
        state.name = name
    },
    setAmountToPay(state, amountToPay) {
        state.amountToPay = amountToPay
    },
    setMaximumPeople(state, maximumPeople) {
        state.maximumPeople = maximumPeople
    },
    setCategoryData(state, data) {
        state.categoryData = data
    },
    setTotalContestAmount(state, adminPercentage) {
        state.totalContestAmount = (
            (state.amountToPay *
                state.maximumPeople *
                (100 - _.toString(adminPercentage))) /
            100
        ).toFixed(2)
    },
    initializeCreateContestForm(state) {
        state.name = ""
        state.amountToPay = ""
        state.maximumPeople = ""
        state.totalContestAmount = "-"
        // state.contestToCreate = {};
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_freePool(state, data) {
        state.isFreePool = data
    },
    privateContest(state, data) {
        state.privateContestData = data
    }
}
export const actions = {
    async getMarketByBetfairId({ commit }, data) {
        commit("set_loading", true)
        commit("setCategoryData", [])
        try {
            let categoryData = await service.apiCallWithHeader(
                "/Category/getMarketByBetfairId",
                data
            )
            if (
                categoryData &&
                categoryData.status &&
                categoryData.status === 200 &&
                categoryData.data &&
                categoryData.data._id
            ) {
                commit("setCategoryData", categoryData.data)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async createContestByPlayer({ commit, state }) {
        try {
            commit("set_loading", true)
            // let ipData = await service.getIp()
            // if (ipData.ip) {
            // }
            let apiData = JSON.parse(localStorage.getItem("contestObj"))
            const pAccessToken = this.$auth.getToken("customLocal")
            let outputData = await service.apiCallWithHeadernew(
                "/v2/createContestByPlayer",
                apiData,
                pAccessToken
            )
            commit("set_loading", false)
            return outputData
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
export const getters = {
    name: (state) => state.name,
    amountToPay: (state) => state.amountToPay,
    maximumPeople: (state) => state.maximumPeople
}
