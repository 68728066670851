import service from "../services/fbApiService"
export const state = () => ({
    data: {},
    loading: false,
    error: {},
    checkPoolType: ""
})

export const mutations = {
    set_data(state, data) {
        state.data = data
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    CHECKPOOLTYPE(state, data) {
        state.checkPoolType = data
    }
}

export const actions = {
    // add by nilam api call function
    async chkUserToken({ commit }, { inviteCode, ipDetail }) {
        // let checkUser;
        const userObj = {
            inviteCode,
            ipDetail
        }
        const getTokenData = await service.apiCallWithHeader(
            "/v2/getContestsBaseOnToken",
            userObj
        )
        return getTokenData
    },
    async checkUserInvitetoken({ commit }, { inviteCode, ipDetail, teamId }) {
        // let checkUser;
        const userObj = {
            inviteCode,
            ipDetail
        }
        // if (teamId) {
        //     userObj.teamId = teamId
        // }
        const getTokenData = await service.apiCallWithHeader(
            "/Pool/getPoolByToken",
            userObj
        )
        return getTokenData
    },
    async registerToPool({ commit, state }, obj) {
        try {
            commit("set_loading", true)
            const pAccessToken = this.$auth.getToken("customLocal")
            const registerPool = await service.apiCallWithHeadernew(
                "/v2/registerToPool",
                obj,
                pAccessToken
            )
            commit("set_loading", false)
            return registerPool
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
