<template>
    <v-app>
        <v-main class="pt-0">
            <snackbar />
            <v-lazy class="fill-height">
                <nuxt />
            </v-lazy>
        </v-main>
    </v-app>
</template>

<script>
import Snackbar from "@/components/layouts/Snackbar"
import eventScript from "@/services/script"
import { indexLink } from "~/constant"
export default {
    components: {
        Snackbar
    },
    head() {
        return {
            link: [
                {
                    rel: "canonical",
                    href: process.env.canonicalUrl + this.$route.path
                }
            ]
        }
    },
    mounted() {
        this.takemethod()
    },
    methods: {
        takemethod() {
            if (process.client) {
                if (
                    !indexLink.includes(window.location.origin) &&
                    process.client
                ) {
                    eventScript.noindexScript()
                }
            }
        }
    }
}
</script>
