export const state = () => ({
    on: true
})

export const mutations = {
    toggle(state) {
        state.on = !state.on
    }
}

export const getters = {
    value: (state) => state.on
}
