import isEmpty from "lodash/isEmpty"
export default async function ({ store, params, route, redirect, env, $auth }) {
    await store.dispatch("notificationMarquee/getGameConfig")

    const casinoGame = JSON.parse(
        JSON.stringify(store.state.notificationMarquee.gameConfigData)
    )
    if (isEmpty(casinoGame)) {
        await store.dispatch("notificationMarquee/getGameconfig")
        const gameConfigDatawac = JSON.parse(
            JSON.stringify(store.state.notificationMarquee.gameConfigData)
        )
        console.log(gameConfigDatawac)
        console.log(route.name)
        if (gameConfigDatawac && route.name !== "casino-casino-list") {
            if (route.name !== "casino-games") {
                return redirect("/casino/casino-list")
            }
        } else if (!gameConfigDatawac && route.name !== "casino-live-casino") {
            return redirect("/casino/live-casino")
        }
    } else {
        const gameConfigDatawac = JSON.parse(
            JSON.stringify(store.state.notificationMarquee.gameConfigData)
        )
        console.log(gameConfigDatawac)
        console.log(route.name)
        if (
            gameConfigDatawac &&
            gameConfigDatawac.wac &&
            route.name !== "casino-casino-list"
        ) {
            if (route.name !== "casino-games") {
                return redirect("/casino/casino-list")
            }
        } else if (
            gameConfigDatawac &&
            !gameConfigDatawac.wac &&
            route.name !== "casino-live-casino"
        ) {
            return redirect("/casino/live-casino")
        }
    }
}
