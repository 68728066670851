// import service from '../services/apiService.js'
import socket1 from "@/plugins/newSocket.client"
export const state = () => ({
    socketId: undefined
})

export const mutations = {
    RESET_SOCKET_ID(state) {
        state.socketId = undefined
    },
    SET_SOCKET_ID(state, data) {
        state.socketId = data
    }
}

export const actions = {
    joinRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("joinRoom", {
                    roomName:
                        "changeTransactionStatusFromAdmin-" + socketId.userId
                })
            }
        } catch (error) {}
    },

    leaveRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("leaveRoom", {
                    roomName:
                        "changeTransactionStatusFromAdmin-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    joinRoomLogin({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const accessToken = this.$auth.getToken('customLocal')
                // const userId = this.$auth.user._id
                socket1.emit("joinRoom", {
                    roomName:
                        "logout_" + socketId.accessToken + "_" + socketId.userId
                })
            }
        } catch (error) {}
    },

    leaveRoomLogin({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const accessToken = this.$auth.getToken('customLocal')
                // const userId = this.$auth.user._id
                socket1.emit("leaveRoom", {
                    roomName:
                        "logout_" + socketId.accessToken + "_" + socketId.userId
                })
            }
        } catch (error) {}
    },
    joinNotificationRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("joinRoom", {
                    roomName: "sendNotificationToUser-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    leaveNotificationRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("leaveRoom", {
                    roomName: "sendNotificationToUser-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    joinBonusRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("joinRoom", {
                    roomName: "userBonus-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    leaveBonusRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("leaveRoom", {
                    roomName: "userBonus-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    // Clear User details
    joinClearUserBonusRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("joinRoom", {
                    roomName: "clearuserdetails-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    leaveClearUserBonusRoom({ state, commit }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (this.$auth.loggedIn && state.socketId) {
                // const userId = this.$auth.user._id
                socket1.emit("leaveRoom", {
                    roomName: "clearuserdetails-" + socketId.userId
                })
            }
        } catch (error) {}
    },
    joinRoomForWithdrawal({ state, commit }, socket1Id) {
        try {
            if (this.$auth.loggedIn && socket1Id) {
                socket1.emit("joinRoom", {
                    roomName: "instantWithdrawLimiter"
                })
            }
        } catch (error) {}
    },
    leaveRoomForWithdrawal({ state, commit }, socket1Id) {
        try {
            if (this.$auth.loggedIn && socket1Id) {
                socket1.emit("leaveRoom", {
                    roomName: "instantWithdrawLimiter"
                })
            }
        } catch (error) {}
    },
    // block user
    joinRoomForBlockUser({ state, commit }, socket1Id) {
        try {
            if (this.$auth.loggedIn && socket1Id) {
                socket1.emit("joinRoom", {
                    roomName: "blocked-" + localStorage.getItem("accessToken")
                })
            }
        } catch (error) {}
    },
    leaveRoomForBlockUser({ state, commit }, socket1Id) {
        try {
            if (this.$auth.loggedIn && socket1Id) {
                socket1.emit("leaveRoom", {
                    roomName: "blocked-" + localStorage.getItem("accessToken")
                })
            }
        } catch (error) {}
    }
}
