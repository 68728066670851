/*eslint-disable*/
import moment from "moment"
import isEmpty from "lodash/isEmpty"
import service from "../services/fbApiService"
import { sumBy, countBy } from "lodash"

export const state = () => ({
    teamLoading: false,
    pointsHasUpdate: false,
    allPlayer: [],
    allowedTeam: "Both",
    allSquadData: {},
    eventRules: {},
    rules: {
        min: null,
        max: null
    },
    createTeamToggle: {
        key: "rating",
        value: false
    },
    captainPageToggle: {
        key: "playingRole",
        value: false
    },
    minimumPlayerRules: {},
    errorStatement: false,
    creditsUsed: 0,
    playersSelected: 0,
    userSelectedPlayer: [],
    showError: "",
    error: {
        one: "",
        two: "",
        three: "",
        four: "",
        five: ""
    }
})

export const mutations = {
    set_loading(state, data) {
        state.teamLoading = data
    },
    setPointsHasUpdate(state, data) {
        state.pointsHasUpdate = data
    },
    setAllUserSelectedPlayersUsingStorage(state, allPlayers) {
        allPlayers.forEach((player) => {
            state.allSquadData[player.playingRole] = JSON.parse(
                JSON.stringify(state.allSquadData[player.playingRole])
            ).map((singlePlayer) => {
                if (
                    singlePlayer.playerInformation == player.playerInformation
                ) {
                    singlePlayer.userSelectedPlayer = true
                }
                return singlePlayer
            })
        })
    },
    sortCaptainPageToggle(state) {
        if (state.captainPageToggle.key == "playingRole") {
            state.allPlayer.sort((a, b) =>
                b.playingRole
                    .toLowerCase()
                    .localeCompare(a.playingRole.toLowerCase())
            )
        } else {
            if (state.captainPageToggle.value) {
                state.allPlayer.sort(function (a, b) {
                    return (
                        a[`${state.captainPageToggle.key}`] -
                        b[`${state.captainPageToggle.key}`]
                    )
                })
            } else {
                state.allPlayer.sort(function (a, b) {
                    return (
                        b[`${state.captainPageToggle.key}`] -
                        a[`${state.captainPageToggle.key}`]
                    )
                })
            }
        }
    },
    changeCaptainPageToggle(state, value) {
        if (value == "playingRole") {
            state.captainPageToggle.key = "playingRole"
            state.captainPageToggle.value = false
        } else if (state.captainPageToggle.key == value) {
            state.captainPageToggle.value = !state.captainPageToggle.value
        } else {
            state.captainPageToggle.key = value
            state.captainPageToggle.value = false
        }
    },
    setAllPlayer(state, data) {
        state.allPlayer = data
    },
    setAllowedTeam(state, data) {
        state.allowedTeam = data
    },
    changeToggle(state, value) {
        if (state.createTeamToggle.key == value) {
            state.createTeamToggle.value = !state.createTeamToggle.value
        } else {
            state.createTeamToggle.key = value
            state.createTeamToggle.value = false
        }
    },
    initializeTeamMembers(state) {
        state.allSquadData = {}
        state.showError = ""
        state.errorStatement = false
        // state.eventRules = {}
        state.rules = {
            min: null,
            max: null
        }
        state.creditsUsed = 0
        state.playersSelected = 0
        state.userSelectedPlayer = []
    },
    setRulesByGameTitle(state, data) {
        state.eventRules.rules.forEach((element) => {
            if (element[data]) {
                state.rules.min = element[data].min
                state.rules.max = element[data].max
            }
        })
    },
    assignPosition(state, data) {
        state.allPlayer.map((singlePlayer) => {
            if (singlePlayer.playerInformation === data.playerId) {
                if (data.position === "isCaptain") {
                    singlePlayer.isCaptain = true
                    singlePlayer.isViceCaptain = false
                } else {
                    singlePlayer.isCaptain = false
                    singlePlayer.isViceCaptain = true
                }
            } else {
                singlePlayer[`${data.position}`] = false
            }
        })
    },
    countSelectedPlayerCredits(state) {
        state.creditsUsed = sumBy(
            [].concat(
                ...Object.values(JSON.parse(JSON.stringify(state.allSquadData)))
            ),
            (singlePlayer) => {
                if (singlePlayer && singlePlayer.userSelectedPlayer) {
                    return singlePlayer.rating
                }
            }
        )
    },
    setAllSelectedPlayers(state) {
        state.userSelectedPlayer = []
            .concat(
                ...Object.values(JSON.parse(JSON.stringify(state.allSquadData)))
            )
            .filter(function (v) {
                return v && v.userSelectedPlayer
            })
    },
    setSelectedPlayerCount(state) {
        state.playersSelected = state.userSelectedPlayer.length
    },
    setAllSquadData(state, data) {
        state.allSquadData = data
    },
    setEventRules(state, data) {
        state.eventRules = data

        JSON.parse(JSON.stringify(state.eventRules.rules)).forEach(
            (singleRule) => {
                state.minimumPlayerRules[Object.keys(singleRule)] =
                    Object.values(singleRule)[0].min
            }
        )
    },
    sortPlayers(state, data) {
        console.log("inside sortPlayers", data)
        Object.keys(state.allSquadData).map(async (key) => {
            await Promise.all(
                state.allSquadData[key].map((singlePlayer) => {
                    if (state.allowedTeam == "Both") {
                        singlePlayer.show = true
                    } else if (state.allowedTeam == singlePlayer.teamName) {
                        singlePlayer.show = true
                    } else {
                        singlePlayer.show = false
                    }
                })
            )
            if (state.createTeamToggle.value) {
                state.allSquadData[key].sort(function (a, b) {
                    return (
                        a[`${state.createTeamToggle.key}`] -
                        b[`${state.createTeamToggle.key}`]
                    )
                })
            } else {
                JSON.parse(JSON.stringify(state.allSquadData))[key].sort(
                    function (a, b) {
                        return (
                            b[`${state.createTeamToggle.key}`] -
                            a[`${state.createTeamToggle.key}`]
                        )
                    }
                )
            }
        })
    },
    changePlayerSelectStatus(state, data) {
        let newData = Object.assign({}, data.player)
        state.allSquadData[newData.playingRole] = JSON.parse(
            JSON.stringify(state.allSquadData[newData.playingRole])
        ).map((singlePlayer) => {
            if (singlePlayer.playerInformation == newData.playerInformation) {
                singlePlayer.userSelectedPlayer = data.show
            }
            return singlePlayer
        })
    },
    eligibility1(state) {
        let groupedByPlayingRole = countBy(
            state.userSelectedPlayer,
            function (obj) {
                return obj.playingRole
            }
        )
        JSON.parse(JSON.stringify(state.eventRules.rules)).forEach(
            (singleRule) => {
                if (
                    groupedByPlayingRole[Object.keys(singleRule)] >=
                    Object.values(singleRule)[0].max
                ) {
                    state.allSquadData[Object.keys(singleRule)] = JSON.parse(
                        JSON.stringify(state.allSquadData)
                    )[Object.keys(singleRule)].map((singlePlayer) => {
                        if (!singlePlayer.userSelectedPlayer) {
                            singlePlayer.maximumPlayerPerSideExceded = true
                            state.error.two = `You can Select only ${
                                Object.values(singleRule)[0].max
                            } ${Object.keys(singleRule)}`
                        }
                        return singlePlayer
                    })
                } else {
                    state.allSquadData[Object.keys(singleRule)] = JSON.parse(
                        JSON.stringify(state.allSquadData)
                    )[Object.keys(singleRule)]?.map((singlePlayer) => {
                        singlePlayer.maximumPlayerPerSideExceded = false
                        return singlePlayer
                    })
                }
            }
        )
    },
    eligibility2(state) {
        if (
            state.userSelectedPlayer.length >=
            state.eventRules.totalPlayersAllowed
        ) {
            Object.keys(state.allSquadData)?.map((key) => {
                state.allSquadData[key]?.map((singlePlayer) => {
                    if (!singlePlayer.userSelectedPlayer) {
                        singlePlayer.totalAllowedExceded = true
                        state.error.three = `You have Selected ${state.eventRules.totalPlayersAllowed} players already`
                    }
                })
            })
        } else {
            Object.keys(state.allSquadData)?.map((key) => {
                state.allSquadData[key]?.map((singlePlayer) => {
                    singlePlayer.totalAllowedExceded = false
                })
            })
        }
    },
    eligibility3(state) {
        let outputData = countBy(state.userSelectedPlayer, function (obj) {
            return obj.teamName
        })
        let output = Object.keys(outputData).filter(function (el) {
            return (
                outputData[el] >= state.eventRules.maxPlayerAllowedBySingleSide
            )
        })
        if (output && output.length) {
            Object.keys(state.allSquadData)?.map((key) => {
                state.allSquadData[key]?.map((singlePlayer) => {
                    if (
                        singlePlayer.teamName == output[0] &&
                        !singlePlayer.userSelectedPlayer
                    ) {
                        singlePlayer.perSideExceded = true
                        state.error.five = `You Can Select only ${state.eventRules.maxPlayerAllowedBySingleSide} players a side`
                    }
                })
            })
        } else {
            Object.keys(state.allSquadData)?.map((key) => {
                state.allSquadData[key]?.map((singlePlayer) => {
                    singlePlayer.perSideExceded = false
                })
            })
        }
    },
    eligibility4(state) {
        Object.keys(state.allSquadData)?.map((key) => {
            state.allSquadData[key]?.map((singlePlayer) => {
                if (
                    !singlePlayer.userSelectedPlayer &&
                    singlePlayer.rating >
                        state.eventRules.totalGivenCredits - state.creditsUsed
                ) {
                    singlePlayer.totalCreditsExceded = true
                    state.error.four =
                        "Not Enough Credits left to select this player"
                } else {
                    singlePlayer.totalCreditsExceded = false
                }
            })
        })
    },
    eligibility5(state) {
        let groupedByPlayingRole = countBy(
            state.userSelectedPlayer,
            function (obj) {
                return obj.playingRole
            }
        )
        let objToFilter = {}
        let spotsLeft =
            state.eventRules.totalPlayersAllowed -
            state.userSelectedPlayer.length
        // if(spotsLeft)
        for (const item in state.minimumPlayerRules) {
            let value = 0
            groupedByPlayingRole[item]
                ? (value = groupedByPlayingRole[item])
                : (value = 0)
            objToFilter[item] =
                state.minimumPlayerRules[item] - value > 0
                    ? state.minimumPlayerRules[item] - value
                    : 0
        }

        if (
            spotsLeft <= Object.values(objToFilter).reduce((a, b) => a + b, 0)
        ) {
            for (const minObj in objToFilter) {
                if (objToFilter[minObj] > 0) {
                    console.log("minObjjjjjjjjjjj", minObj)
                    state.allSquadData[minObj]?.map((singlePlayer) => {
                        if (!singlePlayer.userSelectedPlayer) {
                            singlePlayer.minimumPlayerPerSideExceded = false
                        }
                    })
                    state.error.one = `You need to select ${
                        objToFilter[minObj]
                    } ${objToFilter.all ? "AR" : minObj.toUpperCase()} `
                } else {
                    state.allSquadData[minObj]?.map((singlePlayer) => {
                        if (!singlePlayer.userSelectedPlayer) {
                            singlePlayer.minimumPlayerPerSideExceded = true
                        }
                    })
                }
            }
        } else {
            Object.keys(state.allSquadData)?.map((key) => {
                state.allSquadData[key]?.map((singlePlayer) => {
                    singlePlayer.minimumPlayerPerSideExceded = false
                })
            })
        }
    },
    showError(state, data) {
        state.errorStatement = data
    },
    showErrorData(state, player) {
        if (player.totalAllowedExceded) {
            state.showError = state.error.three
        } else if (player.minimumPlayerPerSideExceded) {
            state.showError = state.error.one
        } else if (player.maximumPlayerPerSideExceded) {
            state.showError = state.error.two
        } else if (player.totalCreditsExceded) {
            state.showError = state.error.four
        } else if (player.perSideExceded) {
            state.showError = state.error.five
        }
    }
}
export const actions = {
    async getMatchSquad({ commit }, data) {
        try {
            commit("set_loading", true)
            let allPlayerData = await service.apiCallWithHeader(
                "/FantasyTeam/getPlayersByQuery",
                data
            )
            if (
                allPlayerData &&
                allPlayerData.status &&
                allPlayerData.status == 200 &&
                allPlayerData.data
            ) {
                commit("setAllSquadData", allPlayerData.data)
                commit("sortPlayers")
            } else {
                commit("setAllSquadData", {})
            }
            commit("set_loading", false)
        } catch (error) {
            commit("setAllSquadData", {})
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getEventRules({ commit }, data) {
        try {
            commit("set_loading", true)
            let eventOutput = await service.apiCallWithHeader(
                "/EventType/getEventRules",
                data
            )
            if (
                eventOutput &&
                eventOutput.status &&
                eventOutput.status == 200 &&
                eventOutput.data
            ) {
                commit("setEventRules", eventOutput.data)
            } else {
                commit("setEventRules", {})
            }
            commit("set_loading", false)
        } catch (error) {
            commit("setEventRules", {})
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
