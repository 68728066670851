export const state = () => ({
    loading: false,
    error: {},
    results: [],
    modalOpen: false
})

export const getters = {
    modalOpen: (state) => state.modalOpen
}

export const mutations = {
    set_modal(state, data) {
        state.modalOpen = data
    },
    set_data(state, data) {
        if (data.value) {
            state.results = data.data
        } else {
            state.results = []
        }
        state.data = data
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}

export const actions = {
    async searchEvent({ commit }, { searchText }) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "/category/searchEvent",
                data: { searchText }
            })
            commit("set_data", response.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
