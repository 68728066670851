export const state = () => ({
    gameList: [],
    providerList: [],
    data: {},
    loading: false,
    error: {}
})

export const mutations = {
    set_gameList(state, data) {
        state.gameList = data
    },
    set_provider_list(state, data) {
        state.providerList = data
    },
    set_data(state, data) {
        const str = data
        const [first, ...rest] = str.split(",")
        console.log(first)
        const remainder = rest.join(",")
        state.data = remainder
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_error(state, data) {
        state.error = data
        console.log("ERROR ====================================")
        console.log(data)
    }
}

export const actions = {
    async getProviders({ commit }, payload) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.FUND_LIST_URL,
                url: "fetchProviderList/getProviders",
                data: payload
            })
            if (response?.data?.data?.outputArray) {
                commit("set_provider_list", response.data.data.outputArray)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
        }
    },
    async getGames({ commit }, payload) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.FUND_LIST_URL,
                url: "fetchGameList/getGames",
                data: payload
            })
            if (response?.data?.data?.outputObject?.data) {
                commit("set_gameList", response.data.data.outputObject.data)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
        }
    },
    async getIframe({ commit }, payload) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.FUND_LIST_URL,
                url: "iFrame/createIFrame",
                data: payload
            })
            console.log("====================================")
            console.log(response?.data?.data)
            console.log("====================================")
            if (response?.data?.data?.outputObject) {
                commit("set_data", response.data.data?.outputObject)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
        }
    }
}

export const getters = {}
