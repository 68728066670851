import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/authenticate.js'), 'authenticate.js')
  resolveStoreModules(require('../store/bankDetails.js'), 'bankDetails.js')
  resolveStoreModules(require('../store/bankTransfer.js'), 'bankTransfer.js')
  resolveStoreModules(require('../store/betBooks.js'), 'betBooks.js')
  resolveStoreModules(require('../store/betBooksFantasy.js'), 'betBooksFantasy.js')
  resolveStoreModules(require('../store/betLoading.js'), 'betLoading.js')
  resolveStoreModules(require('../store/betLoadingFantasy.js'), 'betLoadingFantasy.js')
  resolveStoreModules(require('../store/betRuleAccept.js'), 'betRuleAccept.js')
  resolveStoreModules(require('../store/bettingPL.js'), 'bettingPL.js')
  resolveStoreModules(require('../store/bettingPLNew.js'), 'bettingPLNew.js')
  resolveStoreModules(require('../store/bookmakerMarket.js'), 'bookmakerMarket.js')
  resolveStoreModules(require('../store/changePassword.js'), 'changePassword.js')
  resolveStoreModules(require('../store/confirmBet.js'), 'confirmBet.js')
  resolveStoreModules(require('../store/contest.js'), 'contest.js')
  resolveStoreModules(require('../store/currency.js'), 'currency.js')
  resolveStoreModules(require('../store/drawer.js'), 'drawer.js')
  resolveStoreModules(require('../store/fairplayLogin.js'), 'fairplayLogin.js')
  resolveStoreModules(require('../store/fairplaySockets copy.js'), 'fairplaySockets copy.js')
  resolveStoreModules(require('../store/fairplaySockets.js'), 'fairplaySockets.js')
  resolveStoreModules(require('../store/fancyLadderFantasy.js'), 'fancyLadderFantasy.js')
  resolveStoreModules(require('../store/fancyLadders.js'), 'fancyLadders.js')
  resolveStoreModules(require('../store/fantasyEditStakeSettings.js'), 'fantasyEditStakeSettings.js')
  resolveStoreModules(require('../store/fantasySockets.js'), 'fantasySockets.js')
  resolveStoreModules(require('../store/faqs.js'), 'faqs.js')
  resolveStoreModules(require('../store/favorites.js'), 'favorites.js')
  resolveStoreModules(require('../store/fbMarkets.js'), 'fbMarkets.js')
  resolveStoreModules(require('../store/feedbackForm.js'), 'feedbackForm.js')
  resolveStoreModules(require('../store/finsa.js'), 'finsa.js')
  resolveStoreModules(require('../store/fundlist.js'), 'fundlist.js')
  resolveStoreModules(require('../store/fundlistGame.js'), 'fundlistGame.js')
  resolveStoreModules(require('../store/guestUser.js'), 'guestUser.js')
  resolveStoreModules(require('../store/home.js'), 'home.js')
  resolveStoreModules(require('../store/inplay.js'), 'inplay.js')
  resolveStoreModules(require('../store/ip.js'), 'ip.js')
  resolveStoreModules(require('../store/languageMaster.js'), 'languageMaster.js')
  resolveStoreModules(require('../store/liveCardsIframe.js'), 'liveCardsIframe.js')
  resolveStoreModules(require('../store/liveCasinoIframe.js'), 'liveCasinoIframe.js')
  resolveStoreModules(require('../store/liveCasinoSlotsIframe.js'), 'liveCasinoSlotsIframe.js')
  resolveStoreModules(require('../store/liveGamesIframe.js'), 'liveGamesIframe.js')
  resolveStoreModules(require('../store/maintenance.js'), 'maintenance.js')
  resolveStoreModules(require('../store/matchInner.js'), 'matchInner.js')
  resolveStoreModules(require('../store/matchInside.js'), 'matchInside.js')
  resolveStoreModules(require('../store/megaMenuDrawer.js'), 'megaMenuDrawer.js')
  resolveStoreModules(require('../store/mobileDrawer.js'), 'mobileDrawer.js')
  resolveStoreModules(require('../store/myMarkets.js'), 'myMarkets.js')
  resolveStoreModules(require('../store/nanocosmos.js'), 'nanocosmos.js')
  resolveStoreModules(require('../store/news.js'), 'news.js')
  resolveStoreModules(require('../store/notification.js'), 'notification.js')
  resolveStoreModules(require('../store/notificationMarquee.js'), 'notificationMarquee.js')
  resolveStoreModules(require('../store/openBets.js'), 'openBets.js')
  resolveStoreModules(require('../store/paymentGateway.js'), 'paymentGateway.js')
  resolveStoreModules(require('../store/placeBet-backup.js'), 'placeBet-backup.js')
  resolveStoreModules(require('../store/placeBet.js'), 'placeBet.js')
  resolveStoreModules(require('../store/placeBetFantasy.js'), 'placeBetFantasy.js')
  resolveStoreModules(require('../store/playExch.js'), 'playExch.js')
  resolveStoreModules(require('../store/playWithFriends.js'), 'playWithFriends.js')
  resolveStoreModules(require('../store/pool.js'), 'pool.js')
  resolveStoreModules(require('../store/premiumSportsbook.js'), 'premiumSportsbook.js')
  resolveStoreModules(require('../store/prizeBook.js'), 'prizeBook.js')
  resolveStoreModules(require('../store/productTransfer.js'), 'productTransfer.js')
  resolveStoreModules(require('../store/profile.js'), 'profile.js')
  resolveStoreModules(require('../store/raceBookIframe.js'), 'raceBookIframe.js')
  resolveStoreModules(require('../store/recentMatches.js'), 'recentMatches.js')
  resolveStoreModules(require('../store/referrals.js'), 'referrals.js')
  resolveStoreModules(require('../store/register.js'), 'register.js')
  resolveStoreModules(require('../store/search.js'), 'search.js')
  resolveStoreModules(require('../store/settings.js'), 'settings.js')
  resolveStoreModules(require('../store/share.js'), 'share.js')
  resolveStoreModules(require('../store/slider.js'), 'slider.js')
  resolveStoreModules(require('../store/snackbar.js'), 'snackbar.js')
  resolveStoreModules(require('../store/socketBookmaker.js'), 'socketBookmaker.js')
  resolveStoreModules(require('../store/socketBookmakerMarket.js'), 'socketBookmakerMarket.js')
  resolveStoreModules(require('../store/socketCustomerService.js'), 'socketCustomerService.js')
  resolveStoreModules(require('../store/socketFavorite.js'), 'socketFavorite.js')
  resolveStoreModules(require('../store/socketInplayRate.js'), 'socketInplayRate.js')
  resolveStoreModules(require('../store/socketMain.js'), 'socketMain.js')
  resolveStoreModules(require('../store/socketPremium.js'), 'socketPremium.js')
  resolveStoreModules(require('../store/socketRate.js'), 'socketRate.js')
  resolveStoreModules(require('../store/socketThreeEtRate.js'), 'socketThreeEtRate.js')
  resolveStoreModules(require('../store/sportsbook.js'), 'sportsbook.js')
  resolveStoreModules(require('../store/sportsbookCategory.js'), 'sportsbookCategory.js')
  resolveStoreModules(require('../store/storePL.js'), 'storePL.js')
  resolveStoreModules(require('../store/surveyForm.js'), 'surveyForm.js')
  resolveStoreModules(require('../store/teamMembers.js'), 'teamMembers.js')
  resolveStoreModules(require('../store/threeEt.js'), 'threeEt.js')
  resolveStoreModules(require('../store/ticker.js'), 'ticker.js')
  resolveStoreModules(require('../store/timezone.js'), 'timezone.js')
  resolveStoreModules(require('../store/transaction.js'), 'transaction.js')
  resolveStoreModules(require('../store/transferData.js'), 'transferData.js')
  resolveStoreModules(require('../store/upiDetails.js'), 'upiDetails.js')
  resolveStoreModules(require('../store/user.js'), 'user.js')
  resolveStoreModules(require('../store/userBalance.js'), 'userBalance.js')
  resolveStoreModules(require('../store/wacIframe.js'), 'wacIframe.js')
  resolveStoreModules(require('../store/wacmenu.js'), 'wacmenu.js')
  resolveStoreModules(require('../store/wallet.js'), 'wallet.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
