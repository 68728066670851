import Vue from "vue"

export const hostName = {
    DomainCheck: (DomainHostname) => {
        let mainDomainLink = ""
        const hostname = process.env.dynamicDomain
            ? "localhost"
            : DomainHostname
        const splitHostname = DomainHostname.split(".")
        if (splitHostname.length >= 3) {
            splitHostname.shift()
        }
        mainDomainLink =
            hostname && hostname === "localhost"
                ? process.env.basicDomain
                : hostname && splitHostname.length >= 2
                ? splitHostname.join(".")
                : hostname
        return mainDomainLink
    }
}
export default hostName
Vue.mixin({
    methods: {
        hostName: (DomainHostname) => {
            let mainDomainLink = ""
            const hostname = process.env.dynamicDomain
                ? "localhost"
                : DomainHostname
            const splitHostname = DomainHostname.split(".")
            if (splitHostname.length >= 3) {
                splitHostname.shift()
            }
            mainDomainLink =
                hostname && hostname === "localhost"
                    ? process.env.basicDomain
                    : hostname && splitHostname.length >= 2
                    ? splitHostname.join(".")
                    : hostname
            return mainDomainLink
        }
    }
})
