/* eslint-disable */

import constant from "~/constant"

export default () => {
    ;(function (a, b, c, d, e, f) {
        a[e] = a[e] || []
        a.x = b.createElement(c)
        a.y = b.getElementsByTagName(c)[0]
        x.src = d + "?id=" + f
        x.defer = 1
        x.setAttribute("data-srtmn", e)
        y.parentNode.insertBefore(x, y)
    })(
        window,
        document,
        "script",
        "https://tm.ads.sportradar.com/dist/tag-manager.js",
        "srtmCommands",
        constant.SPORTSRADAR_CLIENT_ID
    )
}
