import socket from "@/plugins/socket.client"
export const state = () => ({
    ids: [],
    idsSingle: [],
    socketId: undefined
})

export const mutations = {
    RESET_SOCKET_ID(state) {
        state.socketId = undefined
    },
    SET_IDS(state, data) {
        state.ids = data
    },
    SET_IDS_SINGLE(state, data) {
        state.idsSingle = data
    },
    ADD_TO_IDS(state, ids) {
        state.ids = [...state.id, ...ids]
    },
    SET_SOCKET_ID(state, data) {
        state.socketId = data
    }
}

export const actions = {
    joinRooms({ commit, state }, { socketId, rejoin = false, betfairId }) {
        commit("SET_SOCKET_ID", socketId)
        let roomNames = []
        if (state.socketId) {
            if (!rejoin) {
                if (state.ids.length > 0) {
                    roomNames = state.ids.map((id) => {
                        if (id?.includes("market_")) {
                            return id
                        }
                        return "market_" + id
                    })
                }
                if (betfairId) {
                    roomNames.push("market_" + betfairId)
                }
                commit("SET_IDS", roomNames)
            } else {
                roomNames = state.ids
            }
            try {
                socket.rateSocket.emit("joinRooms", { roomNames: state.ids })
            } catch (error) {
                const errorData = {}
                errorData.roomNames = state.ids
                this.commit("socketRate/socketFailureLog", error, errorData)
            }
        }
    },
    joinRoomsSingle(
        { commit, state },
        { socketId, rejoin = false, betfairId }
    ) {
        commit("SET_SOCKET_ID", socketId)
        let roomNames = []
        if (state.socketId) {
            if (!rejoin) {
                if (state.ids.length > 0) {
                    roomNames = state.idsSingle.map((idsSingle) => {
                        if (idsSingle?.includes("market_")) {
                            return idsSingle
                        }
                        return "market_" + idsSingle
                    })
                }
                if (betfairId) {
                    roomNames.push("market_" + betfairId)
                }
                // console.log(roomNames)
                commit("SET_IDS_SINGLE", state.idsSingle)
            } else {
                roomNames = state.idsSingle
            }
            try {
                socket.rateSocket.emit("joinRooms", {
                    roomNames: state.idsSingle
                })
            } catch (error) {
                const errorData = {}
                errorData.roomNames = state.idsSingle
                this.commit("socketRate/socketFailureLog", error, errorData)
            }
        }
    },
    leaveRooms({ state, commit }) {
        try {
            if (state.ids.length > 0 && state.socketId) {
                socket.rateSocket.emit("leaveRooms", { roomNames: state.ids })
                commit("SET_IDS", [])
            }
        } catch (error) {
            const errorData = {}
            errorData.roomNames = state.ids
            this.commit("socketRate/socketFailureLog", error, errorData)
        }
    },
    leaveRoomsSingle({ state, commit }) {
        try {
            if (state.idsSingle.length > 0 && state.socketId) {
                socket.rateSocket.emit("leaveRooms", {
                    roomNames: state.idsSingle
                })
                commit("SET_IDS_SINGLE", [])
            }
        } catch (error) {
            const errorData = {}
            errorData.roomNames = state.idsSingle
            this.commit("socketRate/socketFailureLog", error, errorData)
        }
    }
}
