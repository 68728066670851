import service from "../services/apiService"
export const state = () => ({
    data: [],
    error: {},
    loading: false,
    selectedBank: {}
})

export const mutations = {
    set_data(state, data) {
        state.data = data
        if (data && data.length && data.length > 0) {
            state.selectedBank = data[0]
        }
    },
    set_selectedBank(state, value) {
        state.selectedBank = value
    },
    set_admin_bank_details(state, value) {
        if (value.length > 0) {
            state.data = value.map((x) => ({
                accountHolderName: x.accountHolderName,
                accountNumber: x.accountNumber,
                bankName: x.bankName,
                bankBranch: x.bankBranch,
                IFSC: x.IFSC,
                _id: x._id
            }))
        }
    }
}

export const actions = {
    async getBankDetails({ commit }) {
        try {
            const res = await service.apiCallWithHeader(
                "/User/getUserBankDetails"
            )
            if (res && res.status === 200) {
                commit("set_data", res.data.bankDetails)
            }
        } catch (err) {}
    },
    async getAdminBankDetails({ commit }) {
        try {
            const res = await service.apiCallWithHeader(
                "/BankDetail/getAllBankDetailsPaginatedForUser"
            )
            if (res && res.status === 200) {
                commit("set_admin_bank_details", res.data[0].paginatedResults)
            }
        } catch (err) {}
    },
    async updateBankDetails({ commit }, bankAccountDetails) {
        try {
            const res = await service.apiCallWithHeader(
                "/User/updateBankDetails",
                {
                    bankAccountDetails
                }
            )
            if (res && res.status === 200 && res.data === "Success") {
                return "Success"
            }
            return "Error"
        } catch (err) {
            return "Error"
        }
    },
    async deleteBankDetails(_, data) {
        try {
            const res = await service.apiCallWithHeader2(
                "/v2/User/DeleteWithdrawalBank",
                data
            )
            return res.data
            // if (res && res.status === 200) {
            //   return res.data
            // } else {
            //   return 'Network error while deleting Bank Details, try again'
            // }
        } catch (err) {
            return "Network error while deleting Bank Details, try again"
        }
    },
    async getDailyWithdrawTransaction({ commit }) {
        try {
            const res = await service.apiCallWithHeaderUsingGet(
                "/v2/getDailyWithdrawTransaction"
            )
            return res
        } catch (err) {
            return err.response
        }
    }
}
