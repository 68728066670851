export const state = () => ({
    loading: false,
    error: {},
    defaultStake: [50, 500, 1000, 1500, 2000, 3000],
    userConfig: undefined
})

export const mutations = {
    set_data(state, data) {
        if (data.oneClickStake.length < 6) {
            for (
                let index = 0;
                index <= 6 - data.oneClickStake.length + 1;
                index++
            ) {
                data.oneClickStake.push(10000 * (index + 1))
            }
        }
        if (data.mobileStake.length < 6) {
            for (
                let index = 0;
                index <= 6 - data.mobileStake.length + 1;
                index++
            ) {
                data.mobileStake.push(10000 * (index + 1))
            }
        }
        this.$auth.$storage.setUniversal("userConfig", data, true)
        state.userConfig = data
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}

export const actions = {
    async getUserConfig({ commit }) {
        try {
            let userId
            if (this.$auth.loggedIn) {
                userId = this.$auth.user._id
                commit("set_loading", true)
                const response = await this.$axios({
                    method: "post",
                    baseURL: process.env.SPORTSBOOK_API_URL,
                    url: "UserConfig/getUserConfig",
                    data: { user: userId }
                })
                commit("set_data", response.data.data)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async setUserConfig({ commit }, payload) {
        if (this.$auth.loggedIn) {
            payload.user = this.$auth.user._id
        }
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "UserConfig/setUserConfig",
                data: payload
            })
            if (response.data.value === true) {
                commit("set_data", payload)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
