import groupBy from "lodash/groupBy"
import fbService from "@/services/fbApiService"
export const state = () => ({
    data: {},
    cleanFantasyBook: [],
    fantasyFancyData: {},
    cleanData: [],
    newData: {},
    loading: false,
    error: {},
    premiumData: {},
    newPremiumData: {}
})

export const mutations = {
    set_newData(state, data) {
        state.newData = groupBy(data, "marketId")
    },
    reset_newData(state) {
        state.newData = {}
    },
    set_data(state, data) {
        state.cleanData = data
        state.data = groupBy(data, "marketId")
        // console.log(state.data)
    },
    set_fantasy_fancy_data(state, data) {
        state.cleanFantasyBook = data
        state.fancyBookData = groupBy(data, "marketId")
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_premiumData(state, data) {
        state.clearPremiumData = data
        state.premiumData = groupBy(data, "marketUniqueId")
    },
    set_newPremiumData(state, data) {
        state.newPremiumData = groupBy(data, "marketUniqueId")
    },
    reset_newPremiumData(state) {
        state.newPremiumData = {}
    },
    reset_premiumData(state) {
        state.premiumData = {}
    }
}

export const actions = {
    async get({ commit }, matchIds, refresh) {
        if (refresh) {
            commit("set_data", {})
        }
        try {
            const booksOutput = await fbService.apiCallWithHeader(
                "/v2/Bet/getBookPlayer",
                { marketId: matchIds }
            )
            if (
                booksOutput &&
                booksOutput.data &&
                booksOutput.data.status === 200 &&
                booksOutput.data.data
            ) {
                commit("set_data", booksOutput.data.data)
                // this.commit("matchInside/filter_fancy", booksOutput.data.data)
            }
            // commit("set_data", data.data)
            // commit('set_loading', false)
        } catch (error) {
            commit("set_error", error)
            // commit('set_loading', false)
        }
    },
    async getAll({ commit }, matchIds, refresh) {
        if (refresh) {
            commit("set_data", {})
        }
        let accessToken
        if (this.$auth.loggedIn) {
            accessToken = this.$auth.getToken("customLocal")
        }
        try {
            // commit('set_loading', true)
            const { data } = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "book/getBooksForAllMarkets",
                data: {
                    _accessToken: accessToken,
                    matchIds
                }
            })
            commit("set_data", data.data)
            // commit('set_loading', false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getFancy({ commit }, marketId, refresh) {
        const fancyBookData = await fbService.apiCallWithHeader(
            "/v2/getBookForCustomFancy",
            { marketId }
        )
        if (fancyBookData?.data?.data && fancyBookData.status === 200) {
            commit("set_fantasy_fancy_data", fancyBookData.data.data)
            this.commit("matchInner/filter_fancy", fancyBookData.data.data)
        }
    },
    async getAllPremium({ commit }, { refresh, dataSet }) {
        if (refresh) {
            commit("reset_premiumData")
        }
        try {
            if (dataSet) {
                const { data } = await fbService.apiCallWithHeader(
                    "/v2/uofBook",
                    { marketId: dataSet }
                )
                // console.log("getAllPremiumData", data.outputArray)
                if (data && data.outputArray) {
                    // console.log("inside getAllPremiumData111")
                    commit("set_premiumData", data.outputArray)
                    this.commit("matchInner/filter_premium", data.outputArray)
                }
            }
            // commit('set_loading', false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
