import groupBy from "lodash/groupBy"
export const state = () => ({
    data: [],
    isEmpty: false,
    matchIds: [],
    loading: false,
    error: ""
})

export const mutations = {
    set_isEmpty(state, data) {
        state.isEmpty = data
    },
    set_matchIds(state, data) {
        state.matchIds = data
        this.commit("placeBet/set_matchIds", state.matchIds)
    },
    set_data(
        state,
        { live = [], upcoming = [], one = [], three = [], twelve = [] }
    ) {
        const matches = []
        if (live || upcoming) {
            matches.push({
                tab: "Live & Upcoming",
                icon: "mdi-motion-play-outline",
                fixtures: [...live, ...upcoming]
            })
        }
        // if (live) {
        //   matches.push({
        //     tab: 'Live',
        //     icon: 'mdi mdi-access-point',
        //     image: require('@/assets/playicons/Live.svg'),
        //     fixtures: [...live]
        //   })
        // }
        // if (one) {
        //   matches.push({
        //     tab: '1 H',
        //     icon: 'mdi-clock',
        //     image: require('@/assets/playicons/1hr.svg'),
        //     fixtures: [...one]
        //   })
        // }
        // if (three) {
        //   matches.push({
        //     tab: '3 H',
        //     icon: 'mdi-clock-alert',
        //     image: require('@/assets/playicons/3hr.svg'),
        //     fixtures: [...three]
        //   })
        // }
        // if (twelve) {
        //   matches.push({
        //     tab: '12 H',
        //     icon: 'mdi-clock-fast',
        //     image: require('@/assets/playicons/12hr.svg'),
        //     fixtures: [...twelve]
        //   })
        // }
        // if (upcoming) {
        //   matches.push({
        //     tab: 'Upcoming',
        //     icon: 'mdi mdi-calendar-clock',
        //     fixtures: [...upcoming]
        //   })
        // }
        if (live || upcoming) {
            if (
                this.$router.currentRoute.params &&
                this.$router.currentRoute.params.category &&
                this.$router.currentRoute.params.category !== "horse-racing" &&
                this.$router.currentRoute.params.category !== "greyhound-racing"
            ) {
                matches.push({ tab: "Leagues", icon: "mdi-format-list-checks" })
            }
        }
        // if (live || upcoming) {
        //   matches.push({ tab: 'Results', icon: 'mdi-file-check' })
        // }
        state.data = matches
    },

    set_error(state, data) {
        console.log("set_error", data)
        state.error = data
        this.commit("snackbar/open", {
            text: state.error,
            color: "error"
        })
    },

    set_loading(state, data) {
        state.loading = data
    }
}

export const actions = {
    async getMarketsById(
        { commit },
        { game, competitionId, loading = true, socketData, upComing = false }
    ) {
        commit("set_matchIds", [])
        let userId
        try {
            commit("set_loading", loading)
            if (loading) {
                commit("set_data", [])
            }
            let matchData
            if (socketData) {
                matchData = socketData
            } else {
                const { data: responseData } = await this.$axios({
                    method: "post",
                    baseURL: process.env.SPORTSBOOK_API_URL,
                    url: "category/getMarketIds",
                    data: {
                        game,
                        competitionId,
                        _id: userId,
                        _accessToken: this.$auth.getToken("customLocal"),
                        accessLevel: "Player",
                        isFancy: false,
                        upComing
                    }
                })
                matchData = responseData ? responseData.data : undefined
                if (
                    responseData &&
                    responseData.data &&
                    !responseData.data.length
                ) {
                    commit("set_isEmpty", true)
                    commit("set_data", [])
                }
            }
            // console.log('matchdata', matchData.length > 0)
            // setting match ids for socket on
            if (matchData && matchData.length > 0 && Array.isArray(matchData)) {
                commit("set_isEmpty", false)
                const nonBetFairIds = []
                const matchIds = []
                matchData.forEach((market) => {
                    market?.betfairId && matchIds.push(market.betfairId)
                    market?.gameName === "UOFGame" &&
                        market?.eventId &&
                        nonBetFairIds.push(market.eventId)
                })

                if (matchIds.length > 0) {
                    this.commit("socketRate/SET_IDS", matchIds)
                    this.commit("socketBookmaker/SET_IDS", matchIds)
                    // this.commit('socketMain/SET_IDS', matchIds)
                }

                if (nonBetFairIds.length > 0) {
                    const joinRoomName = nonBetFairIds.map((eventId) => {
                        return `uof_main_market_${eventId}`
                    })
                    this.commit("socketRate/SET_EVENT_IDS", joinRoomName)
                }

                commit("set_matchIds", matchIds)

                const mapped = matchData.map((v) => {
                    // const startTime = this.$moment(v.marketStartTime)
                    // const currentTime = this.$moment()
                    // const duration = this.$moment.duration(startTime.diff(currentTime))
                    // const hours = duration.asHours().toFixed(2)
                    // hours < 0
                    //   ? (v.marketStart = 'started')
                    //   : hours > 0 && hours < 1
                    //   ? (v.marketStart = 'one')
                    //   : hours > 1 && hours < 3
                    //   ? (v.marketStart = 'three')
                    //   : hours > 3 && hours < 12
                    //   ? (v.marketStart = 'twelve')
                    //   : (v.marketStart = 'upcoming')
                    if (v) {
                        v.bookMakerCustom =
                            v &&
                            v?.betfairId &&
                            v?.betfairId?.includes("#bookmaker")
                        if (
                            (v.gameName !== "UOFGame" &&
                                v.inPlayStatus &&
                                v.inPlayStatus === "Open") ||
                            (v.gameName === "UOFGame" &&
                                v.markets &&
                                v.markets.length > 0 &&
                                v.markets[0].inPlayStatus === "Open")
                        ) {
                            v.status = "live"
                        } else {
                            v.status = "upcoming"
                        }
                    }
                    return v
                })
                const grTime = groupBy(mapped, "marketStart")
                const grouped = groupBy(mapped, "status")
                const newData = {
                    ...grTime,
                    ...grouped
                }
                if (!loading) {
                    commit("set_data", [])
                }
                commit("set_data", newData)
            } else if (!matchData?.length) {
                commit("set_isEmpty", true)
                commit("set_data", [])
            } else if (!matchData) {
                commit("set_error", "Error getting matches")
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
