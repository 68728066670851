// import moment from 'moment'
import service from "../services/fbApiService"
export const state = () => ({
    myMarketData: [],
    inplayMarket: [],
    myInplayMarket: [],
    myUpcomingInplayMarket: [],
    myAvailableMatchUpcomingCount: 0,
    allAvailabelMarkets: [],
    loading: false,
    error: {},
    isEmpty: false,
    game: "",
    type: "",
    url: "",
    allMarkets: [],
    allMarketsCount: 0,
    availableMatch: [],
    availableMatchCount: 0,
    inplayMarketCount: 0,
    myAvailableMatchCount: 0,
    betRadar_data: {},
    page: 0,
    sportsbookTab: [
        {
            icon: "mdi-access-point",
            text: "Inplay",
            color: "red",
            title: "Live & Upcoming",
            count: 0,
            content: "sportbookBase"
        },
        // {
        //     icon: "mdi-history",
        //     text: "Upcoming",
        //     color: "warning",
        //     title: "My Matches",
        //     count: 0,
        //     content: "sportbookBase"
        // },
        {
            icon: "mdi-web",
            text: "Completed",
            color: "blue",
            title: "Completed",
            content: "sportbookBase"
        }
        // {
        //     icon: "mdi-trophy",
        //     text: "League",
        //     color: "primary",
        //     title: "League"
        // }
    ],
    matchesTab: [
        {
            icon: "mdi-access-point",
            text: "Inplay",
            color: "red",
            title: "Inplay",
            count: 0,
            content: "matches"
        },
        {
            icon: "mdi-history",
            text: "Upcoming",
            color: "warning",
            title: "Upcoming",
            count: 0,
            content: "matches"
        },
        {
            icon: "mdi-trophy",
            text: "Completed",
            color: "success",
            title: "Completed",
            count: 0,
            content: "matches"
        }
    ],
    mainItem: []
    // mainGameItem:[
    //       {
    //         icon: 'iconpe-cricket',
    //         text: 'Cricket',
    //         name: 'Cricket',
    //         link: '/sportsbook/cricket'
    //       },
    //       {
    //         icon: 'mdi-soccer',
    //         text: 'Football',
    //         name: 'Soccer',
    //         link: '/sportsbook/football'
    //       },
    //       {
    //         icon: 'iconpe-tennis',
    //         text: 'Tennis',
    //         name: 'Tennis',
    //         link: '/sportsbook/tennis'
    //       }
    //     ]
})

export const mutations = {
    setTab(state, data) {
        state.mainItem = state[data]
    },
    setPage(state) {
        state.page = ++state.page
    },
    backPage(state) {
        state.page = --state.page
    },
    resetPage(state) {
        state.page = 1
    },
    emptyPage(state) {
        state.page = 0
    },
    set_Uni_count(state, data) {
        const total = state.inplayMarketCount + state.myAvailableMatchCount
        state.sportsbookTab[0].count = total
        const upTotal = state.allMarketsCount
        state.sportsbookTab[1].count = upTotal
    },
    set_allAvailabelMarkets(state, data) {
        state.allAvailabelMarkets = data
    },
    set_url(state, data) {
        state.url = data
    },
    empty_data(state) {
        state.myMarketData = []
    },
    set_data(state, data) {
        state.myMarketData = data
    },
    empty_myinplay_data(state) {
        state.myInplayMarket = []
    },
    empty_myupcominginplay_data(state) {
        state.myUpcomingInplayMarket = []
    },
    set_myinplay_data(state, data) {
        state.myInplayMarket = data
    },
    set_myupcominginplay_data(state, data) {
        state.myUpcomingInplayMarket = data
    },
    set_myinplay_data_count(state, data) {
        state.myAvailableMatchCount = data
    },
    set_myupcominginplay_data_count(state, data) {
        state.myAvailableMatchUpcomingCount = data
    },
    empty_inplay_market(state) {
        state.inplayMarket = []
    },
    set_inplay_market(state, data) {
        state.inplayMarket = data
    },
    set_inplay_market_count(state, data) {
        state.inplayMarketCount = data
    },
    set_error(state, data) {
        state.error = data
    },
    set_isEmpty(state, data) {
        state.isEmpty = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    setCategoryType(state, type) {
        state.type = type
    },
    setGameType(state, game) {
        state.game = game
    },
    setAllMarkets(state, allMarkets) {
        if (state.allMarketsCount < 10) {
            state.allMarkets = []
        }
        allMarkets.forEach((element) => {
            state.allMarkets.push(element)
        })
    },
    setAllMarketsCount(state, marketCount) {
        state.allMarketsCount = marketCount
    },
    initializeAllMatch(state) {
        state.allMarkets = []
        state.myInplayMarket = []
        state.myUpcomingInplayMarket = []
        state.myAvailableMatchUpcomingCount = 0
        state.inplayMarket = []
        state.allMarketsCount = 0
        state.myAvailableMatchCount = 0
        state.inplayMarketCount = 0
        state.page = 0
        // state.type = 'Inplay'
    },
    setAllAvailableMatches(state, data) {
        if (state.availableMatchCount < 10) {
            state.availableMatch = []
        }
        if (data) {
            data.forEach((singleMatch) => {
                state.availableMatch.push(singleMatch)
            })
        }
    },
    setAllAvailableMatchesCount(state, data) {
        state.availableMatchCount = 0
        state.availableMatchCount = data
    },
    initializeAvailableMatch(state) {
        state.availableMatch = []
        state.availableMatchCount = 0
        state.page = 0
    },
    betRadar_data(state, data) {
        state.betRadar_data = data
    }
}

export const actions = {
    async getMarkets({ commit }) {
        const userId = this.$auth.user._id
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "category/getMyMarket",
                data: { user: userId }
            })

            if (
                response.data.error &&
                response.data.error &&
                response.data.error.error === "No Data Found"
            ) {
                commit("set_isEmpty", true)
            } else {
                commit("set_isEmpty", false)
            }

            if (response.data.value) {
                commit("set_data", response.data.data)
            }

            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getAllMarkets({ commit, state }) {
        try {
            commit("set_loading", true)
            // commit('empty_myinplay_data')
            // commit('empty_inplay_market')
            // commit('setAllMarkets', [])
            // commit('set_inplay_market', [])
            // commit('set_inplay_market_count', 0)
            // commit('set_myinplay_data', [])
            // commit('set_myinplay_data_count', 0)
            // commit('setAllMarketsCount', 0)
            const obj = {
                game: state.game ? state.game : "Cricket",
                page: state.page
            }
            let marketsData = ""
            if (this.$auth.loggedIn) {
                commit("set_url", "/v2/Category/getUpcomingMarketIds")
                marketsData = await service.apiCallWithHeader(state.url, obj)
            } else {
                commit("set_url", "/v2/fetchMarket")
                marketsData = await service.apiCallWithData(state.url, obj)
            }
            // commit('empty_myinplay_data')
            // commit('empty_inplay_market')
            // commit('setAllMarkets', [])
            // commit('set_inplay_market', [])
            // commit('set_inplay_market_count', 0)
            // commit('set_myinplay_data', [])
            // commit('set_myinplay_data_count', 0)
            // commit('setAllMarketsCount', 0)
            if (
                marketsData &&
                marketsData.status &&
                marketsData.status === 200 &&
                marketsData.data &&
                marketsData.data.data
            ) {
                const liveMatch =
                    marketsData.data.data.getAllLiveMatches.paginatedResults
                const upcomingMatch =
                    marketsData.data.data.getAllUpcomingMatches.paginatedResults
                // commit('empty_myinplay_data')
                // commit('empty_inplay_market')
                commit("setAllMarkets", upcomingMatch)
                commit(
                    "setAllMarketsCount",
                    marketsData.data.data.getAllUpcomingMatches.dataCount
                )
                commit("set_inplay_market", liveMatch)
                commit(
                    "set_inplay_market_count",
                    marketsData.data.data.getAllLiveMatches.dataCount
                )
                if (!this.$auth.loggedIn) {
                    const liveUpcomingMatch = liveMatch.concat(upcomingMatch)
                    commit("set_allAvailabelMarkets", liveUpcomingMatch)
                }
                if (this.$auth.loggedIn) {
                    commit(
                        "set_myinplay_data",
                        marketsData.data.data.getMyAllLiveMatches
                            .paginatedResults
                    )
                    commit(
                        "set_myinplay_data_count",
                        marketsData.data.data.getMyAllLiveMatches.dataCount
                    )
                    commit(
                        "set_myupcominginplay_data",
                        marketsData.data.data.getMyAllUpcomingMatches
                            .paginatedResults
                    )
                    commit(
                        "set_myupcominginplay_data_count",
                        marketsData.data.data.getMyAllUpcomingMatches.dataCount
                    )
                }
                // commit('set_Uni_count')
            }
            // else if (
            //     // state.type &&
            //     // state.type === "Inplay" &&
            //     marketsData &&
            //     marketsData.status &&
            //     marketsData.status === 200 &&
            //     marketsData.data &&
            //     marketsData.data.body
            // ) {
            //     if (
            //         marketsData.data.body.getAllLiveMatches &&
            //         marketsData.data.body.getAllLiveMatches.paginatedResults
            //     ) {
            //         commit(
            //             "set_inplay_market",
            //             marketsData.data.body.getAllLiveMatches.paginatedResults
            //         )
            //         commit(
            //             "set_inplay_market_count",
            //             marketsData.data.body.getAllLiveMatches.paginatedResults
            //                 .length
            //         )
            //     }
            //     if (
            //         marketsData.data.body.getMyAllLiveMatches &&
            //         marketsData.data.body.getMyAllLiveMatches.paginatedResults
            //     ) {
            //         commit(
            //             "set_myinplay_data",
            //             marketsData.data.body.getMyAllLiveMatches
            //                 .paginatedResults
            //         )
            //         commit(
            //             "set_myinplay_data_count",
            //             marketsData.data.body.getMyAllLiveMatches
            //                 .paginatedResults.length
            //         )
            //     }
            // }
            else if (
                // state.type &&
                // state.type === "Inplay" &&
                marketsData &&
                marketsData.status &&
                marketsData.status === 200 &&
                marketsData.data &&
                marketsData.data.data &&
                marketsData.data.data.paginatedResults
            ) {
                commit(
                    "set_inplay_market",
                    marketsData.data.data.paginatedResults
                )
                commit(
                    "set_inplay_market_count",
                    marketsData.data.data.dataCount
                )
            } else if (
                marketsData &&
                marketsData.status &&
                marketsData.status === 200 &&
                marketsData.data &&
                marketsData.data.data &&
                marketsData.data.data.paginatedResults
            ) {
                commit("setAllMarkets", marketsData.data.data.paginatedResults)
                commit("setAllMarketsCount", marketsData.data.data.dataCount)
            }
            commit("set_Uni_count")
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getAllMatchesMarkets({ commit, state }) {
        try {
            commit("set_loading", true)
            const obj = {
                game: "Cricket",
                page: state.page,
                match: "Completed",
                status: "Disable"
            }
            // if (state.type === "Completed") {
            //     obj.status = "Disable"
            // } else {
            //     obj.status = "Enable"
            // }
            const availableMatch = await service.apiCallWithHeader(
                "/v2/getMyMatches",
                obj
            )
            if (
                availableMatch &&
                availableMatch.data &&
                availableMatch.data.status &&
                availableMatch.data.status === 200 &&
                availableMatch.data.data &&
                availableMatch.data.data.paginatedResults
            ) {
                commit(
                    "setAllAvailableMatches",
                    availableMatch.data.data.paginatedResults
                )
                commit(
                    "setAllAvailableMatchesCount",
                    availableMatch.data.data.dataCount
                )
            } else {
                commit("setAllAvailableMatches", [])
                commit("setAllAvailableMatchesCount", 0)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("setAllAvailableMatches", [])
            commit("setAllAvailableMatchesCount", 0)
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
    // async getDumyMarkets({ commit, state }, betfairId) {
    //     try {
    //         const json = {
    //             betfairId: betfairId.toString()
    //         }
    //         const getDummyData = await service.apiCallForDummyMarkets(
    //             "/Category/getbetRadarVideo",
    //             json
    //         )
    //         if (
    //             getDummyData &&
    //             getDummyData.status === 200 &&
    //             getDummyData.data &&
    //             getDummyData.data.data
    //         ) {
    //             commit("betRadar_data", getDummyData.data.data)
    //         } else {
    //             commit("betRadar_data", {})
    //         }
    //     } catch (error) {}
    // }
}
