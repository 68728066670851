import service from "../services/apiService"
import encryptor from "../plugins/encryption"
import constant from "../constant"
export const state = () => ({
    loading: false,
    data: {},
    userCategory: {},
    error: {},
    limiter: {},
    depositsBank: [],
    bankTransferLimiter: {},
    trunOver: {},
    globalLockData: {},
    walletList: [],
    userDetail: {},
    checkUserDetail: false,
    zivoWebcallData: {},
    despositCount: false
})

export const mutations = {
    set_data(state, value) {
        state.data = value
    },
    set_userCategory(state, value) {
        state.userCategory = value
    },

    set_error(state, data) {
        state.error = data
    },

    set_limiter(state, data) {
        state.limiter = data
    },
    set_turnOver(state, data) {
        state.turnOver = data
    },
    set_walletList(state, data) {
        state.walletList = data
    },
    set_bankTransferLimiter(state, data) {
        state.bankTransferLimiter = data
    },
    set_depositsBank(state, data) {
        state.depositsBank = data
    },
    set_zivoWebcall(state, data) {
        state.zivoWebcallData = data
    },
    set_despositCount(state, data) {
        if (data > 0) {
            state.despositCount = true
        } else {
            state.despositCount = false
        }
    }
}
export const actions = {
    async getWalletDetails({ commit }, objToSend) {
        try {
            if (!localStorage || !localStorage.getItem("accessToken")) {
                return
            }
            const res = await service.apiCallWithHeader(
                "/Wallet/getUserWalletBalanceForParticularCurrency",
                objToSend
            )
            if (res && res.status === 200) {
                commit("set_data", res.data)
            }
        } catch (err) {}
    },

    async getWithdrawalLimit({ commit }) {
        try {
            const response = await service.apiCallWithHeaderUsingGet(
                "/v2/withdrawLimiter"
            )
            if (
                response &&
                response.data &&
                response.data.status === 200 &&
                response.data.data
            ) {
                commit("set_limiter", response.data.data)
            } else {
                commit("set_limiter", {})
            }
        } catch (err) {
            commit("set_error", {
                message: err.message || "No Internet connection"
            })
        }
    },
    async getTrunOverDetailsForWallet({ commit }) {
        try {
            if (localStorage && localStorage.getItem("accessToken")) {
                if (sessionStorage.getItem("userCategory")) {
                    commit(
                        "set_userCategory",
                        await JSON.parse(sessionStorage.getItem("userCategory"))
                    )
                    return JSON.parse(sessionStorage.getItem("userCategory"))
                }
                const response = await service.apiCallWithHeaderUsingGet(
                    "/v2/User/getUserTurnoverCategoryDetails"
                )
                if (
                    response &&
                    response.status === 200 &&
                    response.data &&
                    response.data.data
                ) {
                    commit("set_userCategory", response.data.data)
                    sessionStorage.setItem(
                        "userCategory",
                        JSON.stringify(response.data.data)
                    )
                }
                return response
            }
            return
        } catch (err) {
            commit("set_error", {
                message: err.message || "No Internet connection"
            })
        }
    },
    async changeUserCategoryByTurnover({ commit }) {
        try {
            sessionStorage.removeItem("userCategory")
            commit("set_userCategory", {})
            if (localStorage && localStorage.getItem("accessToken")) {
                const response = await service.apiCallWithHeader2(
                    "/v2/changeUserCategoryByTurnover"
                )
                if (
                    response &&
                    response.status === 200 &&
                    response.data &&
                    response.data.data
                ) {
                    return response
                }
            }
        } catch (err) {
            commit("set_error", {
                message: err.message || "No Internet connection"
            })
        }
    },
    async getCategories({ commit }) {
        try {
            const response = await service.apiCallWithHeaderUsingGet(
                "/v2/getCategories"
            )
            if (
                response &&
                response.status === 200 &&
                response.data &&
                response.data.data
            ) {
                return response
            }
        } catch (err) {
            commit("set_error", {
                message: err.message || "No Internet connection"
            })
        }
    },
    async getGiftcardCode({ commit }, objgiftcard) {
        try {
            const response = await service.apiCallWithHeader(
                "/v2/giftcardBonus",
                {
                    giftCardCode: objgiftcard.giftCardCode
                }
            )
            return response
        } catch (err) {
            commit("set_error", {
                message: err.message || "No Internet connection"
            })
        }
    },
    async checkWithdrawCount({ commit }, value) {
        try {
            const res = await service.apiCallWithHeader2(
                "/v2/checkWithdrawCount",
                {
                    instantWithdraw: value
                }
            )
            if (res && res.status === 200) {
                return res
            }
        } catch (err) {
            return err.response
        }
    },
    async getBankTransfer({ commit }) {
        try {
            if (!localStorage || !localStorage.getItem("accessToken")) {
                return
            }
            const res = await service.apiCallWithUserManagementWithHeader(
                "/v2/banktransfers"
            )
            // return res
            if (res && res.status === 200 && res.data && res.data.data) {
                commit("set_bankTransferLimiter", res.data.data)
            } else {
                commit("set_bankTransferLimiter", {})
            }
        } catch (err) {
            // return err.response
            commit("set_error", {
                message: err.message || "No Internet connection"
            })
        }
    },
    async getPaymentGateway({ commit }) {
        try {
            if (!localStorage || !localStorage.getItem("accessToken")) {
                return
            }
            const res = await service.apiCallWithUserManagementWithHeader(
                "/v2/getPaymentGateway"
            )
            return res
        } catch (err) {
            commit("set_error", {
                message:
                    err && err.message ? err.message : "Something Went Worng!"
            })
        }
    },
    async getZivoWebCallSetting({ commit }) {
        try {
            if (!localStorage || !localStorage.getItem("accessToken")) {
                return
            }
            if (sessionStorage.getItem("zivoWebcall")) {
                const data = await JSON.parse(
                    sessionStorage.getItem("zivoWebcall")
                )
                commit("set_zivoWebcall", data)
                return
            }
            const res = await service.apiCallWithUserManagementWithHeader(
                "/v2/zivoWebcall"
            )
            if (res && res.status === 200 && res.data && res.data.data) {
                sessionStorage.setItem(
                    "zivoWebcall",
                    JSON.stringify(res.data.data)
                )
                commit("set_zivoWebcall", res.data.data)
            }
        } catch (err) {
            commit("set_error", {
                message:
                    err && err.message ? err.message : "Something Went Worng!"
            })
        }
    },
    async getGlobalLock({ commit }) {
        try {
            if (!localStorage || !localStorage.getItem("accessToken")) {
                return
            }
            const res = await service.apiCallForGlobalLock(
                "/api/member/getGlobalLock"
            )
            if (res && res.status === 200) {
                return res.data.data
            }
        } catch (err) {}
    },
    getWalletList({ commit }) {
        try {
            if (!localStorage || !localStorage.getItem("accessToken")) {
                return
            }
            if (localStorage && localStorage.getItem("bdWalletlist")) {
                const localres = JSON.parse(
                    encryptor.decrypt(localStorage.getItem("bdWalletlist"))
                )
                commit("set_walletList", localres)
            } else {
                const walletlist = [
                    {
                        name: "Bkash",
                        image: "bkash"
                    },
                    {
                        name: "Nagad",
                        image: "nagad"
                    },
                    {
                        name: "Rocket",
                        image: "roketlogo"
                    }
                ]

                localStorage.setItem(
                    "bdWalletlist",
                    encryptor.encrypt(JSON.stringify(walletlist))
                )
                commit("set_walletList", walletlist)

                // const response = await service.apiCallWithHeaderUsingGet(
                //     "/v2/wrapper/fairpay/getWalletList"
                // )
                // if (
                //     response &&
                //     response.data &&
                //     response.data.status === 200 &&
                //     response.data.data
                // ) {
                //     localStorage.setItem(
                //         "bdWalletlist",
                //         encryptor.encrypt(JSON.stringify(response.data.data))
                //     )
                //     commit("set_walletList", response.data.data)
                // } else {
                //     commit("set_walletList", [])
                // }
            }
        } catch (err) {
            commit("set_error", {
                message: err.message || "No Internet connection"
            })
        }
    },
    commonUserDetail() {
        if (sessionStorage && sessionStorage.getItem("getOneUser")) {
            this.userDetail = JSON.parse(
                encryptor.decrypt(sessionStorage.getItem("getOneUser"))
            )
            if (
                this.userDetail &&
                this.userDetail.data &&
                this.userDetail.data.userData &&
                this.userDetail.data.userData.userRisk
            ) {
                this.checkUserDetail = true
            }
        }
    },
    async getAllBankData({ commit, dispatch, store }) {
        try {
            if (!localStorage || !localStorage.getItem("accessToken")) {
                return
            }
            const bankData = []
            if (this.state.user.configurationData.isWhatsAppDeposit) {
                // // FPbook
                try {
                    if (process.client) {
                        if (
                            sessionStorage &&
                            sessionStorage.getItem("getOneUser")
                        ) {
                            this.userDetail = JSON.parse(
                                encryptor.decrypt(
                                    sessionStorage.getItem("getOneUser")
                                )
                            )
                            if (
                                localStorage.getItem("user_affilates") === "" ||
                                localStorage.getItem("user_affilates") ===
                                    null ||
                                (localStorage.getItem("user_affilates") &&
                                    constant.affiliatesCodeArray &&
                                    constant.affiliatesCodeArray.length > 0 &&
                                    constant.affiliatesCodeArray.includes(
                                        localStorage.getItem("user_affilates")
                                    ))
                            ) {
                                if (
                                    this.state.user.whatsappData &&
                                    this.state.user.whatsappData.isWhatsAppNo &&
                                    this.state.profile.appversion
                                        .projectCode !== "bd-new"
                                ) {
                                    bankData.push({
                                        tab: "",
                                        content: "FPbook",
                                        image: "website-logo",
                                        logo: "wpng",
                                        alt: "FPbook"
                                    })
                                }
                            }
                        }
                    }
                } catch (error) {
                    commit("set_error", {
                        message: error.message || "No Internet connection"
                    })
                }
            }

            // Payment Gateway
            try {
                let checkPgData = {}
                const res = await service.apiCallWithUserManagementWithHeader(
                    "/v2/getPaymentGateway"
                )
                if (res && res.status === 200 && res.data && res.data.data) {
                    checkPgData = res.data.data
                }
                await this.dispatch("wallet/commonUserDetail")
                if (
                    checkPgData &&
                    checkPgData.isPgOnOff &&
                    this.checkUserDetail &&
                    checkPgData.displayPgUserRisk &&
                    checkPgData.displayPgUserRisk.includes(
                        this.userDetail.data.userData.userRisk
                    )
                ) {
                    bankData.push({
                        tab: "Payment Gateway",
                        content: "PaymentGateway",
                        image: "wallet-payment-methods",
                        alt: "wallet payment methods"
                    })
                } else {
                    bankData.slice({
                        tab: "Payment Gateway",
                        content: "PaymentGateway",
                        image: "wallet-payment-methods",
                        alt: "wallet payment methods"
                    })
                }
            } catch (error) {
                commit("set_error", {
                    message: error.message || "No Internet connection"
                })
            }

            // get Praxis
            try {
                let checkPraxisData = {}
                const res = await service.apiCallWithUserManagementWithHeader(
                    "/v2/getPraxisAdmin"
                )
                if (res && res.status === 200 && res.data && res.data.data) {
                    checkPraxisData = res.data.data
                }
                if (checkPraxisData && checkPraxisData.isPraxisOnOff) {
                    bankData.push({
                        tab: "Net Banking",
                        content: "Praxis",
                        image: "wallet-payment-methods",
                        alt: "Net Banking"
                    })
                } else {
                    bankData.slice({
                        tab: "Net Banking",
                        content: "Praxis",
                        image: "wallet-payment-methods",
                        alt: "Net Banking"
                    })
                }
            } catch (error) {
                commit("set_error", {
                    message: error.message || "No Internet connection"
                })
            }

            // Bank transfers
            try {
                const now = this.$moment().utc().format("HH:mm")
                let check = false
                let checkBankData = {}
                await this.dispatch("wallet/commonUserDetail")
                const res = await service.apiCallWithUserManagementWithHeader(
                    "/v2/banktransfers"
                )
                if (res && res.status === 200 && res.data && res.data.data) {
                    checkBankData = res.data.data
                    commit("set_bankTransferLimiter", res.data.data)
                } else {
                    commit("set_bankTransferLimiter", {})
                }
                const from = checkBankData.bankTransferStartTime
                const to = checkBankData.bankTransferEndTime
                if (now >= from && now <= to) {
                    check = true
                }
                if (
                    checkBankData &&
                    check &&
                    checkBankData.bankTransfer &&
                    checkBankData.bankTransfer === true &&
                    this.checkUserDetail &&
                    checkBankData.displayBankUserRisk &&
                    checkBankData.displayBankUserRisk.includes(
                        this.userDetail.data.userData.userRisk
                    )
                ) {
                    bankData.push({
                        tab: "Bank Transfers",
                        content: "BankTransfer",
                        image: "bank",
                        alt: "wallet bank"
                    })
                }
            } catch (error) {
                commit("set_error", {
                    message: error.message || "No Internet connection"
                })
            }

            // MAldoPay
            try {
                let checkMpData = {}
                const now = this.$moment().utc().format("HH:mm")
                const res = await service.apiCallWithUserManagementWithHeader(
                    "/v2/getMaldoPay"
                )
                if (res && res.status === 200 && res.data && res.data.data) {
                    checkMpData = res.data.data
                }
                await this.dispatch("wallet/commonUserDetail")
                if (
                    checkMpData &&
                    checkMpData.maldoPay &&
                    this.checkUserDetail &&
                    checkMpData.displayMaldoUserRisk &&
                    checkMpData.displayMaldoUserRisk.includes(
                        this.userDetail.data.userData.userRisk
                    )
                ) {
                    const from = checkMpData.maldoPayStartTime
                    const to = checkMpData.maldoPayEndTime
                    if (now >= from && now <= to) {
                        bankData.push({
                            tab: "Astropay",
                            content: "AstroPay",
                            image: "astropay",
                            alt: "Astropay"
                        })
                    } else {
                        bankData.slice({
                            tab: "Astropay",
                            content: "AstroPay",
                            image: "astropay",
                            alt: "Astropay"
                        })
                    }
                }
            } catch (error) {
                commit("set_error", {
                    message: error.message || "No Internet connection"
                })
            }

            // Crypto Transfers
            try {
                let checkCryptoData = {}
                const res = await service.apiCallWithUserManagementWithHeader(
                    "/v2/getCryptoTransfer"
                )
                if (res && res.status === 200 && res.data && res.data.data) {
                    checkCryptoData = res.data.data
                }

                if (
                    checkCryptoData &&
                    checkCryptoData.isCryptoTransferOnOff &&
                    this.checkUserDetail &&
                    checkCryptoData.displayCryptotTansferUserRisk.includes(
                        this.userDetail.data.userData.userRisk
                    )
                ) {
                    bankData.push({
                        tab: "Crypto",
                        content: "CryptoTransfer",
                        image: "crypto",
                        alt: "wallet crypto"
                    })
                } else {
                    bankData.slice({
                        tab: "Crypto",
                        content: "CryptoTransfer",
                        image: "crypto",
                        alt: "wallet crypto"
                    })
                }
            } catch (error) {
                commit("set_error", {
                    message: error.message || "No Internet connection"
                })
            }

            let totalData = []
            totalData = totalData.concat(bankData)
            commit("set_depositsBank", totalData)
        } catch (error) {}
    },
    checkAffiliateProcess() {
        // normal user hai toh ..... display
        // Client affiliate hai toh .... display
        // simple affiliate hai toh ..... gyab
        if (process.client) {
            if (
                localStorage.getItem("user_affilates") === "" ||
                localStorage.getItem("user_affilates") === null ||
                (localStorage.getItem("user_affilates") &&
                    constant.affiliatesCodeArray &&
                    constant.affiliatesCodeArray.length > 0 &&
                    constant.affiliatesCodeArray.includes(
                        localStorage.getItem("user_affilates")
                    ))
            ) {
                return true
            } else {
                return false
            }
        }
    },
    async checkDepositCountForUser({ commit }) {
        if (sessionStorage && sessionStorage.getItem("getOneUser")) {
            try {
                // This will stored data for 10mins.
                if (
                    sessionStorage.getItem("despositCount_timezone") &&
                    sessionStorage.getItem("despositCount")
                ) {
                    const aa = sessionStorage.getItem("despositCount_timezone")
                    const now = new Date().getTime()
                    const distance = now - aa
                    const seconds = Math.floor(distance / 1000)
                    const minutes = Math.floor(seconds / 60)
                    if (minutes >= 10) {
                        sessionStorage.removeItem("despositCount")
                        sessionStorage.removeItem("despositCount_timezone")
                    }
                }
                if (sessionStorage.getItem("despositCount")) {
                    const data = await JSON.parse(
                        encryptor.decrypt(
                            sessionStorage.getItem("despositCount")
                        )
                    )
                    commit("set_despositCount", data)
                    return
                }
                const res = await service.apiCallWithHeaderUsingGet(
                    "/v2/totalDeposit"
                )
                if (res && res.status === 200 && res.data) {
                    sessionStorage.setItem(
                        "despositCount",
                        encryptor.encrypt(
                            JSON.stringify(res.data.totalDepositCount)
                        )
                    )
                    commit("set_despositCount", res.data.totalDepositCount)
                    sessionStorage.setItem(
                        "despositCount_timezone",
                        new Date().getTime()
                    )
                }
            } catch (error) {}
        }
    }
}
