<template>
    <v-app>
        <cache-clear-modal />
        <notification-marquee></notification-marquee>
        <v-main class="pt-0">
            <snackbar />
            <v-lazy class="fill-height">
                <transition name="slide-fade" :duration="550">
                    <nuxt />
                </transition>
            </v-lazy>
        </v-main>
    </v-app>
</template>

<script>
import Snackbar from "@/components/layouts/Snackbar"
import NotificationMarquee from "@/components/NotificationMarquee"
import CacheClearModal from "@/components/fairplay/modals/CacheClearModal"
import eventScript from "@/services/script"
import { indexLink } from "~/constant"
export default {
    name: "Empty",
    components: {
        NotificationMarquee,
        Snackbar,
        CacheClearModal
    },
    middleware: ["maintenanceCheck", "favIconCheck", "fairplaySport"],
    head() {
        return {
            link: [
                {
                    rel: "canonical",
                    href: process.env.canonicalUrl + this.$route.path
                }
            ]
        }
    },
    created() {
        this.$store.dispatch("languageMaster/getAllLanguages")
    },
    mounted() {
        import("@/plugins/encryption.js")
        this.takemethod()
        eventScript.facebookOverallPixel()
    },
    methods: {
        takemethod() {
            if (process.client) {
                if (
                    !indexLink.includes(window.location.origin) &&
                    process.client
                ) {
                    eventScript.noindexScript()
                }
            }
        }
    }
}
</script>
