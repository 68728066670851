import encryptor from "~/plugins/encryption.js"

export const state = () => ({
    data: [],
    loading: false,
    error: {}
})

export const mutations = {
    set_data(state, data) {
        if (data.length) {
            state.data = data.sort((a, b) => a.order - b.order)
        }
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_error(state, data) {
        state.error = data
    }
}

export const actions = {
    async getSlider({ commit }) {
        try {
            // This will stored data for 30mins.
            if (
                sessionStorage.getItem("set_Banner_timezone") &&
                sessionStorage.getItem("set_Banner")
            ) {
                const aa = sessionStorage.getItem("set_Banner_timezone")
                const now = new Date().getTime()
                const distance = now - aa
                const seconds = Math.floor(distance / 1000)
                const minutes = Math.floor(seconds / 60)
                if (minutes >= 30) {
                    sessionStorage.removeItem("set_Banner")
                    sessionStorage.removeItem("set_Banner_timezone")
                }
            }
            if (process.client) {
                if (sessionStorage.getItem("set_Banner")) {
                    const data = await JSON.parse(
                        encryptor.decrypt(sessionStorage.getItem("set_Banner"))
                    )
                    commit("set_data", data)
                    return
                }
            }

            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.CONFIG_V2_API_URL,
                url: "Banner/getBanner",
                data: { page: 1, keyword: "" }
            })
            if (response.data.value) {
                commit("set_data", response.data.data)
                sessionStorage.setItem(
                    "set_Banner",
                    encryptor.encrypt(JSON.stringify(response.data.data))
                )
            }
            sessionStorage.setItem("set_Banner_timezone", new Date().getTime())
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
