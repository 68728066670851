import socket from "@/plugins/socket.client"
export const state = () => ({
    ids: [],
    socketId: undefined
})

export const mutations = {
    RESET_SOCKET_ID(state) {
        state.socketId = undefined
    },
    SET_IDS(state, data) {
        state.ids = data
            .filter((match) => !match.includes("bookmaker"))
            .map((d) => `favouriteMarket_${d}`)
    },
    SET_SOCKET_ID(state, data) {
        state.socketId = data
    }
}

export const actions = {
    joinRooms({ commit, state }, socketId) {
        try {
            commit("SET_SOCKET_ID", socketId)
            if (state.ids.length > 0 && state.socketId) {
                socket.rateSocket.emit("joinRooms", { roomNames: state.ids })
            }
        } catch (error) {
            const errorData = {}
            errorData.roomNames = state.ids
            this.commit("socketRate/socketFailureLog", error, errorData)
        }
    },
    leaveRooms({ state, commit }) {
        try {
            if (state.ids.length > 0 && state.socketId) {
                socket.rateSocket.emit("leaveRooms", { roomNames: state.ids })
                commit("SET_IDS", [])
            }
        } catch (error) {
            const errorData = {}
            errorData.roomNames = state.ids
            this.commit("socketRate/socketFailureLog", error, error.errorData)
        }
    }
}