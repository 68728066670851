export default function ({ $auth, env, redirect, route }) {
    if (
        env.ONLY_LOGIN &&
        env.ONLY_LOGIN === "true" &&
        route.name === "forgot-password"
    ) {
        return redirect("/forgot-password")
    } else if (env.ONLY_LOGIN && env.ONLY_LOGIN === "true" && !$auth.loggedIn) {
        return redirect("/login")
    }
}
