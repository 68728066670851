export default function ({ env, redirect, route }) {
    if (env.fairplayEnvironment !== "PROD") return
    // if (
    //     route.name === "quick-signup" ||
    //     route.name === "fast-signup" ||
    //     route.name === "registration" ||
    //     route.name === "fp-registration" ||
    //     route.name === "registration2" ||
    //     route.name === "registration3" ||
    //     route.name === "quick-signup-2" ||
    //     route.name === "register" ||
    //     route.name === "check-in" ||
    //     route.name === "quick-checkin" ||
    //     route.name === "enroll-now" ||
    //     route.name === "quick-enroll"
    // ) {
    //     redirect("/")
    // }
}
