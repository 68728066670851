const middleware = {}

middleware['CheckBlogUrl'] = require('../middleware/CheckBlogUrl.js')
middleware['CheckBlogUrl'] = middleware['CheckBlogUrl'].default || middleware['CheckBlogUrl']

middleware['fairplaySport'] = require('../middleware/fairplaySport.js')
middleware['fairplaySport'] = middleware['fairplaySport'].default || middleware['fairplaySport']

middleware['favIconCheck'] = require('../middleware/favIconCheck.js')
middleware['favIconCheck'] = middleware['favIconCheck'].default || middleware['favIconCheck']

middleware['freshChat'] = require('../middleware/freshChat.js')
middleware['freshChat'] = middleware['freshChat'].default || middleware['freshChat']

middleware['maintenanceCheck'] = require('../middleware/maintenanceCheck.js')
middleware['maintenanceCheck'] = middleware['maintenanceCheck'].default || middleware['maintenanceCheck']

middleware['onlylogin'] = require('../middleware/onlylogin.js')
middleware['onlylogin'] = middleware['onlylogin'].default || middleware['onlylogin']

middleware['register-redirect'] = require('../middleware/register-redirect.js')
middleware['register-redirect'] = middleware['register-redirect'].default || middleware['register-redirect']

middleware['validateCasino'] = require('../middleware/validateCasino.js')
middleware['validateCasino'] = middleware['validateCasino'].default || middleware['validateCasino']

middleware['validateGame'] = require('../middleware/validateGame.js')
middleware['validateGame'] = middleware['validateGame'].default || middleware['validateGame']

middleware['validateUser'] = require('../middleware/validateUser.js')
middleware['validateUser'] = middleware['validateUser'].default || middleware['validateUser']

export default middleware
