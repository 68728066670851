import service from "../services/apiService"

export const state = () => ({
    data: {},
    apiResponse: ""
})

export const mutations = {
    set_apiResponse(state, value) {
        state.apiResponse = value
    }
}

export const actions = {
    async depositBankTransfer({ commit }, transactionObj) {
        try {
            const res = await service.apiCallWithHeader(
                "/Transaction/userDepositBankTransferAndOffline",
                transactionObj
            )
            if (
                res &&
                res.status === 200 &&
                res.data === "Transaction Initiated"
            ) {
                commit("set_apiResponse", {
                    response: "success",
                    message: res.data
                })
            } else if (
                res &&
                res.status === 200 &&
                res.data === "Transaction Initiated"
            ) {
                commit("set_apiResponse", {
                    response: "error",
                    message: res.data || "Transaction Id Exist"
                })
            } else {
                commit("set_apiResponse", {
                    response: "error",
                    message:
                        res.data ||
                        "No Internet Connection while deposit transaction, try again"
                })
            }
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while deposit transaction, try again"
            })
        }
    },
    async withdrawBankTransfer({ commit }, transactionObj) {
        try {
            const res = await service.apiCallWithHeader(
                "/Transaction/withdrawRequestForBankTransferAndOffline",
                transactionObj
            )
            if (
                res &&
                res.status === 200 &&
                res.data === "Transaction Initiated"
            ) {
                commit("set_apiResponse", {
                    response: "success",
                    message: res.data
                })
            } else {
                commit("set_apiResponse", {
                    response: "error",
                    message:
                        res.data ||
                        "No Internet Connection while withdraw transaction, try again"
                })
            }
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while withdraw transaction, try again"
            })
        }
    },
    async kheloCardTransfer({ commit }, transactionObj) {
        try {
            const res = await service.apiCallWithHeader(
                "/Card/cardTransaction",
                transactionObj
            )

            if (
                res &&
                res.status &&
                res.status === 200 &&
                res.data.status === 200
            ) {
                commit("set_apiResponse", {
                    response: "success",
                    message: res.data.message
                })
            } else {
                commit("set_apiResponse", {
                    response: "error",
                    message:
                        res.data.message ||
                        "No Internet Connection while withdraw transaction, try again"
                })
            }
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while withdraw transaction, try again"
            })
        }
    },
    async CashDepositTransfer({ commit }, transactionObj) {
        try {
            const res = await service.apiCallWithHeader(
                "/v2/Transaction/userCashDeposit",
                transactionObj
            )
            return res
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while withdraw transaction, try again"
            })
        }
    },
    async ifscValidator({ commit }, obj) {
        try {
            return await service.apiCallWithHeader2("/v2/ifscvalidator", obj)
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while validating ifsc, try again"
            })
        }
    },
    async couponCodeValidator({ commit }, obj) {
        try {
            return await service.apiCallWithHeader2(
                "/v2/validateCouponCodes",
                obj
            )
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while validating coupon, try again"
            })
        }
    }
}
