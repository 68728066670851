/*eslint-disable*/
// import moment from 'moment'
import isEmpty from "lodash/isEmpty"
import service from "../services/fbApiService"
import { countBy } from "lodash"

export const state = () => ({
    loading: false,
    loading2: false,
    loading3: false,
    marketId: "",
    gameType: "PREDICTION",
    activeTab: 0,
    innerActiveTab: 0,
    availableTabs: [],
    teamPoolData: [],
    winningPrice: {},
    from: [],
    to: [],
    tab: 0,
    contestId: "",
    contestAmount: 0,
    allContests: [],
    myContests: [],
    myJoinContests: [],
    allContestsAvailable: 0,
    myContestsAvailable: 0,
    contestToJoin: "",
    allContestPage: 1,
    myContestPage: 1,
    adminPercentage: 0,
    setSkeltonLoader: false,
    byContestSize: 1,
    byEntrySize: -1,
    filterContest: true,
    categoryData: {},
    matchRunner: [],
    myContestStatus: "Enable",
    teamData: [],
    myTeamPage: 1,
    myTeamAvailable: 0,
    teamMember: [],
    allTeamsData: [],
    modalVariable: false,
    modalVariableNew: false,
    checkBetFairId: "",
    predictInMatchTab: [
        {
            name: "All Contest",
            icon: "mdi-hexagon-multiple-outline",
            color: "green",
            content: "AllContest",
            tabInd: 0
        },
        // {
        //     name: "My Contest",
        //     icon: "mdi-star-shooting-outline",
        //     color: "blue",
        //     content: "MyContest",
        //     tabInd: 1
        // },
        {
            name: "private contest",
            icon: "mdi-account-multiple-outline",
            color: "primary",
            content: "PlayWithFriends",
            tabInd: 1
        }
    ],
    // createATeamTab: [
    //     {
    //         name: "All Contest",
    //         icon: "mdi-hexagon-multiple-outline",
    //         color: "green",
    //         content: "AllContest",
    //         tabInd: 0
    //     },
    //     {
    //         name: "My Contest",
    //         icon: "mdi-star-shooting-outline",
    //         color: "blue",
    //         content: "MyContest",
    //         tabInd: 1
    //     },
    //     {
    //         name: "Play With Friends",
    //         icon: "mdi-account-multiple-outline",
    //         color: "primary",
    //         content: "PlayWithFriends",
    //         tabInd: 2
    //     },
    //     {
    //         name: "My Teams",
    //         icon: "mdi-account-group-outline",
    //         color: "red",
    //         content: "MyTeams",
    //         tabInd: 3
    //     }
    // ],
    mainTabItem: [],
    privatePoolCounts: {}
})

export const mutations = {
    setFromToData(state) {
        const poolData = state.teamPoolData.map((item) => {
            return item.priceDistribution
        })
        const fromData = poolData[0].map((from) => {
            return from.from
        })
        state.from = fromData
        const toData = poolData[0].map((to) => {
            return to.to
        })
        state.to = toData
    },
    assignTab(state, data) {
        state.mainTabItem = state.mainTabItem.filter((singleItem) => {
            if (
                data.matchType == "Completed" &&
                data.gameType == "PREDICTION"
            ) {
                return singleItem.content === "MyContest"
            } else if (
                (data.matchType == "Completed" &&
                    data.gameType == "FANTASY_TEAM") ||
                (data.matchType == "Inplay" && data.gameType == "FANTASY_TEAM")
            ) {
                return (
                    singleItem.content === "MyContest" ||
                    singleItem.content == "MyTeams"
                )
            } else {
                return singleItem
            }
        })
    },
    setAvailableTabs(state) {
        state.availableTabs.push("PREDICTION")
        if (
            state.categoryData &&
            state.categoryData.fantasyDetails &&
            state.categoryData.fantasyDetails.matchId
        ) {
            state.availableTabs.push("FANTASY_TEAM")
        }
        state.availableTabs = [...new Set(state.availableTabs)]
    },
    setTab(state, data) {
        state.mainTabItem = state[data.val]
        state.tab = data.num
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_loading2(state, data) {
        state.loading2 = data
    },
    set_loading3(state, data) {
        state.loading3 = data
    },
    setTeamPoolData(state, data) {
        state.teamPoolData = data
    },
    setContestModal(state, data) {
        state.modalVariable = data
    },
    setContestModalNew(state, data) {
        state.modalVariableNew = data
    },
    initializeAllTeamsData(state) {
        state.allTeamsData = []
    },
    setContestAmount(state, data) {
        state.contestAmount = data
    },
    setContestId(state, data) {
        state.contestId = data
    },
    setContestByFilterContestSizes(state, data) {
        if (data) {
            state.allContests.sort((a, b) =>
                a.maximumPeople > b.maximumPeople ? -1 : 1
            )
            state.myContests.sort((a, b) =>
                a.maximumPeople > b.maximumPeople ? -1 : 1
            )
        } else {
            state.allContests.sort((a, b) =>
                a.maximumPeople < b.maximumPeople ? -1 : 1
            )
            state.myContests.sort((a, b) =>
                a.maximumPeople < b.maximumPeople ? -1 : 1
            )
        }
    },
    setContestByFilterEntryAmount(state, data) {
        if (data) {
            state.allContests.sort((a, b) =>
                a.amountToPay > b.amountToPay ? -1 : 1
            )
            state.myContests.sort((a, b) =>
                a.amountToPay > b.amountToPay ? -1 : 1
            )
        } else {
            state.allContests.sort((a, b) =>
                a.amountToPay < b.amountToPay ? -1 : 1
            )
            state.myContests.sort((a, b) =>
                a.amountToPay < b.amountToPay ? -1 : 1
            )
        }
    },
    // changeVariable(state, data) {
    //   state.variable = data;
    // },
    setallTeamsData(state, data) {
        state.allTeamsData = data
    },
    initializeContests(state) {
        state.allContests = []
        state.myContests = []
        state.myJoinContests = []
        state.teamData = []
        state.allContestPage = 1
        state.myContestPage = 1
        state.setSkeltonLoader = false
        state.byContestSize = 1
        state.byEntrySize = -1
        state.filterContest = true
        state.allContestsAvailable = 0
        state.myContestsAvailable = 0
    },
    initializePrizeBook(state) {
        state.contestId = ""
        state.contestAmount = 0
        state.gameType = "PREDICTION"
        state.activeTab = 0
        state.innerActiveTab = 0
        state.allContests = []
        state.myContests = []
        state.myJoinContests = []
        state.allContestsAvailable = 0
        state.myContestsAvailable = 0
        state.contestToJoin = ""
        state.allContestPage = 1
        state.myContestPage = 1
        state.setSkeltonLoader = false
        state.byContestSize = 1
        state.byEntrySize = -1
        state.filterContest = true
        state.categoryData = {}
        state.myContestStatus = "Enable"
    },
    setPlayersCount(state, data) {
        let countByOutput = countBy(data, (obj) => {
            return obj.teamName
        })

        state.matchRunner = state.matchRunner.map((singleRunner) => {
            singleRunner.playersCount = countByOutput[singleRunner.name]
            return singleRunner
        })
    },
    initializeRunner(state) {
        state.matchRunner = []
    },

    setMyContestStatus(state, data) {
        state.myContestStatus = data
    },
    initializeAllContests(state) {
        state.allContests = []
        state.allContestsAvailable = 0
        state.allContestPage = 1
    },
    initializeMyContests(state) {
        state.myContestPage = 1
        state.myContests = []
        state.myContestsAvailable = 0
    },
    initializeMyTeamContests(state) {
        state.myTeamPage = 1
        state.myTeamAvailable = 0
        state.teamData = []
    },
    setGameCategoryType(state, data) {
        state.gameType = data
    },
    activetabindex(state, data) {
        state.activeTab = data
    },
    innerActiveTabindex(state, data) {
        state.innerActiveTab = data
    },
    setMarketId(state, data) {
        state.marketId = data
    },
    setBetfairId(state, data) {
        state.checkBetFairId = data
    },
    setAllContests(state, data) {
        // state.allContests = [] // clear state
        data.map((singleContest) => {
            let firstPrice = singleContest.priceDistribution.find(
                (singlePrice) => {
                    return singlePrice.from == 1 || singlePrice.from == 0
                }
            )
            singleContest.lastRank = _.last(singleContest.priceDistribution).to
            singleContest.totalWinnersInPercent =
                (singleContest.lastRank * 100) / singleContest.maximumPeople
            singleContest.firstPrice = firstPrice?.winningPerHead
            state.allContests.push(singleContest)
        })
        state.allContests = [
            ...new Set(state.allContests.map((o) => JSON.stringify(o)))
        ].map((string) => JSON.parse(string))
        // state.allContests = [...state.allContests].reverse()
        const duplicateIds = []
        state.allContests.forEach((obj) => {
            if (state.allContests.includes(obj._id) === -1) {
                state.allContests.push(obj)
            } else {
                duplicateIds.push(obj)
            }
        })
        if (duplicateIds.length > 0) {
            duplicateIds.forEach((id) => {
                const indexesToRemove = []
                state.allContests.forEach((obj, index) => {
                    if (obj._id === id._id) {
                        indexesToRemove.push(index)
                    }
                })
                for (let i = indexesToRemove.length - 1; i > 0; i--) {
                    state.allContests.splice(indexesToRemove[i], 1)
                }
            })
        }
    },
    allContestsCount(state, data) {
        state.allContestsAvailable = data
    },
    resetAllContestPageCount(state) {
        state.allContestPage = 1
    },
    increaseAllContestPageCount(state) {
        state.allContestPage = ++state.allContestPage
    },
    resetMyContestPageCount(state) {
        state.myContestPage = 1
    },
    increaseMyContestPageCount(state) {
        state.myContestPage = ++state.myContestPage
    },
    stateSkeltonLoader(state, data) {
        state.setSkeltonLoader = data
    },
    setMyContests(state, data) {
        data.map((singleContest) => {
            let firstPrice = singleContest.priceDistribution.find(
                (singlePrice) => {
                    return singlePrice.from == 1
                }
            )
            singleContest.firstPrice = firstPrice?.winningPerHead
            singleContest.lastRank = _.last(singleContest.priceDistribution).to

            singleContest.totalWinnersInPercent =
                (singleContest.lastRank * 100) / singleContest.maximumPeople
            state.myContests.push(singleContest)
        })
        state.myContests = [
            ...new Set(state.myContests.map((o) => JSON.stringify(o)))
        ].map((string) => JSON.parse(string))
        const duplicateIds = []
        state.myContests.forEach((obj) => {
            if (state.myContests.includes(obj._id) === -1) {
                state.myContests.push(obj)
            } else {
                duplicateIds.push(obj)
            }
        })
        if (duplicateIds.length > 0) {
            duplicateIds.forEach((id) => {
                const indexesToRemove = []
                state.myContests.forEach((obj, index) => {
                    if (obj._id === id._id) {
                        indexesToRemove.push(index)
                    }
                })
                for (let i = indexesToRemove.length - 1; i > 0; i--) {
                    state.myContests.splice(indexesToRemove[i], 1)
                }
            })
        }
    },
    myContestsCount(state, data) {
        state.myContestsAvailable = data
    },
    setContestToJoin(state, data) {
        state.contestToJoin = data
    },
    setFilterByContestSize(state, data) {
        state.byContestSize = data
    },
    setFilterType(state, data) {
        state.filterContest = data
    },
    setFilterByEntry(state, data) {
        state.byEntrySize = data
    },
    set_WinningPrice(state, data) {
        state.winningPrice = data
        const uniqueIds = {}
        if (
            state.winningPrice &&
            Array.isArray(state.winningPrice) &&
            state.winningPrice.length
        ) {
            for (let i = state.winningPrice.length - 1; i >= 0; i--) {
                const id = state.winningPrice[i]._id

                if (uniqueIds[id]) {
                    state.winningPrice.splice(i, 1)
                } else {
                    uniqueIds[id] = true
                }
            }
        }
    },
    clear_WinningPrice(state) {
        state.winningPrice = {}
    },
    setCategoryData(state, data) {
        state.categoryData = data
    },
    setAdminPercentage(state, data) {
        state.adminPercentage = data
    },
    setMatchRunner(state) {
        JSON.parse(
            JSON.stringify(state.categoryData.fantasyDetails.runners)
        ).forEach((singleRunner) => {
            if (singleRunner.name != "The Draw") {
                state.matchRunner.push(singleRunner)
            }
        })
    },
    setTeamData(state, data) {
        state.teamData = data
    },
    setAllMember(state, data) {
        state.teamMember = data
    },
    emptyAllMember(state) {
        state.teamMember = []
    },
    setjoinContest(state, data) {
        state.myJoinContests = data
    },
    setPrivatePoolCounts(state, data) {
        state.privatePoolCounts = data
    },
    resetAllContests(state, data) {
        state.allContests = []
    },
    resetSetMyContests(state, data) {
        state.myContests = []
    }
}
export const actions = {
    async getMarketByBetfairId({ commit }, data) {
        commit("set_loading", true)
        commit("setCategoryData", [])
        try {
            let categoryData = await service.apiCallWithHeader(
                "/Category/getMarketByBetfairId",
                data
            )
            if (
                categoryData &&
                categoryData.status &&
                categoryData.status === 200 &&
                categoryData.data &&
                categoryData.data._id &&
                categoryData.data.betfairId
            ) {
                commit("setBetfairId", categoryData.data.betfairId)
                commit("setCategoryData", categoryData.data)
                commit(
                    "setAdminPercentage",
                    categoryData.data.comissionAmount || 0
                )
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getContests({ state, commit }, data) {
        commit("set_loading3", true)
        let queryObject = {
            marketId: data.marketId,
            type: data.gameType,
            page: state.allContestPage,
            status: state.myContestStatus
        }
        if (state.filterContest == true) {
            queryObject.contestSize = state.byContestSize
        } else {
            queryObject.contestEntry = state.byEntrySize
        }
        try {
            let outputData = await service.apiCallWithHeader(
                "/v2/getContests",
                queryObject
            )
            commit("resetAllContests", [])
            commit("allContestsCount", 0)
            commit("resetSetMyContests", [])
            commit("myContestsCount", 0)
            if (outputData && outputData.status && outputData.status === 200) {
                if (
                    outputData.data &&
                    outputData.data.data &&
                    outputData.data.data.allContest &&
                    outputData.data.data.allContest[0] &&
                    outputData.data.data.allContest[0].paginatedResults
                ) {
                    if (state.gameType === data.gameType) {
                        commit("set_loading3", true)
                        commit(
                            "setAllContests",
                            outputData.data.data.allContest[0].paginatedResults
                        )
                        commit(
                            "allContestsCount",
                            outputData.data.data.allContest[0].totalCount[0]
                                .count
                        )
                        commit("set_loading3", false)
                    }
                }

                if (
                    outputData.data &&
                    outputData.data.data &&
                    outputData.data.data.myContest &&
                    outputData.data.data.myContest[0] &&
                    outputData.data.data.myContest[0].paginatedResults
                ) {
                    if (state.gameType === data.gameType) {
                        commit("set_loading3", true)
                        commit(
                            "setMyContests",
                            outputData.data.data.myContest[0].paginatedResults
                        )
                        commit(
                            "myContestsCount",
                            outputData.data.data.myContest[0].totalCount[0]
                                .count
                        )
                        commit("set_loading3", false)
                        // if (
                        //     outputData.data.myContest[0].totalCount[0].count !==
                        //     0
                        // ) {
                        //     commit("activetabindex", 1)
                        // } else {
                        //     commit("activetabindex", 0)
                        // }
                    }
                    let matchObject = {
                        marketId: data.marketId,
                        type: data.gameType
                    }
                    // let getOutput = await service.apiCallWithHeader(
                    //     "/v2/getUserWinningAmount",
                    //     matchObject
                    // )
                    // console.log("getOutput!!!!", getOutput)
                    if (data.matchType === "Completed") {
                        try {
                            let getOutput = await service.apiCallWithHeader(
                                "/v2/getUserWinningAmount",
                                matchObject
                            )
                            if (
                                getOutput &&
                                getOutput.status &&
                                getOutput.status === 200
                            ) {
                                if (
                                    getOutput &&
                                    getOutput.data &&
                                    getOutput.data.data &&
                                    getOutput.data.data.length
                                ) {
                                    commit(
                                        "set_WinningPrice",
                                        getOutput.data.data
                                    )
                                }
                            } else {
                                commit("clear_WinningPrice")
                            }
                        } catch (error) {
                            console.log("error", error)
                        }
                    }
                }

                if (
                    outputData.data &&
                    outputData.data.data &&
                    outputData.data.data.privatePoolCounts
                ) {
                    if (state.gameType === data.gameType) {
                        commit("set_loading3", true)
                        commit(
                            "setPrivatePoolCounts",
                            outputData.data.data.privatePoolCounts
                        )
                        commit("set_loading3", false)
                    }
                }

                if (
                    outputData.data &&
                    outputData.data.myTeams &&
                    outputData.data.myTeams[0] &&
                    outputData.data.myTeams[0].teamId
                ) {
                    commit("setTeamData", outputData.data.myTeams)
                }
            }
            commit("set_loading3", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading3", false)
        }
    },

    //Not testest by @Tony
    // not neeeded at the moment
    // async getAllContests({ state, commit }, data) {
    //     commit("set_loading", true)
    //     let queryObject = {
    //         marketId: data.marketId,
    //         type: state.gameType,
    //         page: state.allContestPage
    //     }
    //     if (state.filterContest == true) {
    //         queryObject.contestSize = state.byContestSize
    //     } else {
    //         queryObject.contestEntry = state.byEntrySize
    //     }
    //     let allContests = await service.apiCallWithHeader(
    //         "/Pool/getPoolsByMarketIdAndType",
    //         queryObject
    //     )
    //     commit("set_loading", false)
    //     if (
    //         allContests &&
    //         allContests.data &&
    //         allContests.data[0] &&
    //         allContests.data[0].paginatedResults &&
    //         allContests.data[0].paginatedResults.length
    //     ) {
    //         commit("setAllContests", allContests.data[0].paginatedResults)
    //         commit("allContestsCount", allContests.data[0].totalCount[0].count)
    //     } else if (
    //         state.page == 1 &&
    //         allContests &&
    //         allContests.data &&
    //         allContests.data.message &&
    //         allContests.data.message == "no data found"
    //     ) {
    //         commit("initializeAllContests")
    //     }
    // },
    async getMyContests({ state, commit }, data) {
        if (state.myContestPage == 1) {
            commit("set_loading", true)
        }
        let allContests = await service.apiCallWithHeader(
            "/Pool/getPoolPointsInPlay",
            {
                marketId: data.marketId,
                type: state.gameType,
                page: state.myContestPage,
                status: state.myContestStatus
            }
        )
        commit("set_loading", false)
        if (
            allContests &&
            allContests.data &&
            allContests.data[0] &&
            allContests.data[0].paginatedResults &&
            allContests.data[0].paginatedResults.length
        ) {
            commit("setMyContests", allContests.data[0].paginatedResults)
            commit("myContestsCount", allContests.data[0].totalCount[0].count)
        }
    },
    async getMyTeamData({ commit, state }, data) {
        commit("set_loading2", true)
        let getTeamData = await service.apiCallWithHeader(
            "/FantasyTeam/getAllTeamsByMarketPerUser",
            data,
            { page: 1 }
        )
        if (getTeamData && getTeamData.data && getTeamData.data.length) {
            commit("setTeamData", getTeamData.data)
        }
        commit("set_loading2", false)
    },
    async getAllTeam({ commit, state }, data) {
        commit("set_loading2", true)
        let getAllMember = await service.apiCallWithHeader(
            "/FantasyTeam/getTeamById",
            data,
            { page: 1 }
        )
        if (getAllMember && getAllMember.data) {
            commit("setAllMember", getAllMember.data)
        }
        commit("set_loading2", false)
    },
    async getTeamsLefttoJoinByUserPerMarket({ commit }, data) {
        try {
            commit("set_loading", true)
            let getTeamData = await service.apiCallWithHeader(
                "/FantasyTeam/getTeamsLefttoJoinByUserPerMarket",
                data,
                { page: state.page }
            )
            commit("set_loading", false)
            if (getTeamData && getTeamData.data && getTeamData.data.length) {
                commit("setallTeamsData", getTeamData.data)
            }
        } catch (error) {
            commit("set_loading", false)
            commit("set_error", error)
        }
    },
    async getContestsBaseToken({ commit, state }, data) {
        let getTokenDetails = await service.apiCallWithHeader(
            "/v2/getContestsBaseOnToken",
            data,
            { page: 1 }
        )
        if (getTokenDetails && getTokenDetails.data) {
            commit("setjoinContest", getTokenDetails.data)
        }
    },
    async getTeamInnerPoolData({ commit, state }, data) {
        try {
            commit("setTeamPoolData", [])
            let getPoolData = await service.apiCallWithHeader(
                "/v2/getOnePool",
                data
            )
            if (
                getPoolData &&
                getPoolData.data &&
                getPoolData.status === 200 &&
                getPoolData.data.data
            ) {
                commit("setTeamPoolData", getPoolData.data.data)
            }
        } catch (error) {
            commit("setTeamPoolData", [])
        }
    }
}
